import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import ZTitle from 'components/core/ZTitle';

import { useLocalStorage } from 'hooks/useStorage';

//import { useQuery, useMutation } from '@apollo/client';
//import { getClient,  getQuotesForClient } from 'queries';
//import { addClient, updateClient } from 'mutations';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getSensor,  getSensorStateUpdate, getAllSensors } from 'queries_urql';
import { addClient, updateSensor, deleteTrigger, prodigySensorRetrain, prodigySensorRetrainI, prodigySensorAutoencode, addActivity } from 'mutations_urql';
import { subscribeAllActiveJobBoardEntries, subscribeSensor, subscribeSensorStateUpdate } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Moment from 'react-moment';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import TimelineIcon from '@material-ui/icons/Timeline';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ZWizard from 'components/core/ZWizard';

import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';
import ZSensorBox from 'components/boxes/ZSensorBox';
import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';

import ZFluentCalendar from 'components/core/ZFluentCalendar'; 
import ZAssignSensorToTriggerButton from 'components/buttons/ZAssignContactSensorToTriggerButton'//ZAssignSensorToTriggerButton
import ZAnnotationsList from 'components/lists/ZAnnotationsList';// ZAnnotationsList
import ZAttachmentsList from 'components/lists/ZAttachmentsList';// ZAttachmentsList
import ZTicketsList from 'components/lists/ZTicketsList';//ZTicketsList
import ZSensorsList from 'components/lists/ZSensorsList';
import ZAddAnnotationDialog from 'components/dialogs/ZAddAnnotationDialog';
import ZAddTicketDialog from 'components/dialogs/ZAddTicketDialog';
import ZImageListHorizontal from 'components/lists/ZImageListHorizontal';
import ZMeasurementList from 'components/lists/ZMeasurementList';
import ZMeasurementPlot from 'components/charts/ZMeasurementPlot'; 
import ZMeasurementPlotly from 'components/charts/ZMeasurementPlotly';
import ZSensorRMSPlotly from 'components/charts/ZSensorRMSPlotly'; //ZSensorRMSPlotly
import ZSetAnalysisBloCButton from 'components/buttons/ZSetAnalysisBloCButton'; 

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import { downloadObjectFromAzure } from 'azure/AzureBlobAPI';
import Collapse from '@material-ui/core/Collapse';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import ReactTooltip from 'react-tooltip';
import eventBus from 'utils/EventBus';

import mqtt from 'mqtt';

//////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  scrollablePaper: {
    overflowY: 'scroll',
    maxHeight: 800,
  },  
  paper: {
    width: 400,
    height: 800,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

//////////////////////////////////////////////

const ZSensorView = (props) => {
  const theme = useTheme();
  console.log('ZSensorView->props');
  console.log(props);

  let RMS_THRESHOLD = 0.2

  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });

  const classes = useStyles();

  let { id } = useParams();
  const [userData, setUserData] = useLocalStorage('userData');

  const stateupdate = React.useRef(null)

  const [state, setState] = React.useState({
    selectedData: [],
    showAnnotationDialog: false,
    showTicketDialog: false,
    showSensorDialog: false,
    startDate: undefined,
    endDate: undefined
  });


  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });
  
  const [tabIndex, setTabIndex] = React.useState(0);

  let row = undefined;
  let measurementMap = {};
  let selectedData = [];
  let selectionModel = [];
  let selectedAnalysisModel = [];

  //let stateupdate = null;
  //let shouldPause = false;

  const handleCloseAnnotation = () => {
    setState({...state, showAnnotationDialog: false});
  }  

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };


  const handleSelectionModelSelect = (selectionModel) => {
    console.log('handleSelectionModelSelect')
    console.log(selectionModel)

    selectedData = []
    selectionModel = [...selectionModel]

    for(var i=0; i < selectionModel.length; i++){
      var mm = selectionModel[i];
      var measurement = measurementMap[mm];
      console.log(`selecting data for: ${mm} - ${measurement.apath} - ${measurement.timestamp}`)
      console.log(measurement.apath);
      selectedData.push(measurement.apath)
      //console.log(selectedData)
    }

    console.log(selectedData)
    //setState({...state, selectedData: rawData})
  }


  const handleSelectionAnalysisModelSelect = (selectionModel) => {
    console.log('handleSelectionAnalysisModelSelect')
    console.log(selectionModel)

    selectedAnalysisModel = [...selectionModel]

    console.log(selectedAnalysisModel)
  }

  const [deleteTriggerResult, deleteTriggerFunction] = useMutation(deleteTrigger);

  const handleTriggerDelete = (trigger_id) => {
    console.log('handleTriggerDelete')
    console.log(trigger_id)

    let variables = {
      id: trigger_id,
    }

    console.log(variables);
    
    deleteTriggerFunction(variables).then((result)=>{
      console.log(result);
      if(result.error){
        console.log(`ERROR deleting trigger: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'})
      } else {
        console.log(`trigger deleted`)
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully deleted sensor trigger', severity: 'success'})
      }
    })  
  }

  const togglePause = () => {
    setState({...state, shouldPause: !state.shouldPause})
  }

  const [updateSensorResult, updateFunction] = useMutation(updateSensor);
  const [activityResult,     activityFunction]   = useMutation(addActivity);
  const [retrainResult,     blocRetrainEventFunction]   = useMutation(prodigySensorRetrainI);
  const [analyzeResult,     blocAnalyzeEventFunction]   = useMutation(prodigySensorAutoencode);


  /////////////////
  const handleSubscription = (previousData, response) => {
    console.log('handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    //row = response.zeus_tool_by_pk
    return response
    //jobs = data.zeus_jobboard_entry
  };

  const handleStateUpdateSubscription = (previousData, response) => {
    console.log('handleStateUpdateSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    //row = response.zeus_tool_by_pk
    return response
    //jobs = data.zeus_jobboard_entry
  };  
 
  console.log('setting up subscription')

  const [subResult, executeSubscription] = useSubscription({ 
    query: subscribeSensor,
    variables: { id },
    pause: true,
    //pause: !window.navigator.onLine,
    //pause: state.showBookingDialog,
  }, handleSubscription); 


  const [stateSubResult, executeStateSubscription] = useSubscription({ 
    query: subscribeSensorStateUpdate,
    variables: { id },
    //pause: true,
    //pause: !window.navigator.onLine,
    //pause: state.showBookingDialog,
  }, handleStateUpdateSubscription); 

  const [ queryResult, reexecuteQuery ]  = useQuery({
    query: getSensor,
    variables: { id },
    //pause: window.navigator.onLine,
  });

  const [ allResult, reexecuteAllQuery ]  = useQuery({
    query: getAllSensors,
    //pause: window.navigator.onLine,
  });

  const [ stateResult, reexecuteStateQuery ]  = useQuery({
    query: getSensorStateUpdate,
    variables: { id },
  });

  ///let { data, fetching, error } = subResult
  let { data, fetching, error } = queryResult

  /*
  if(!window.navigator.onLine){
    console.log('not online')
    data = queryResult.data;
    fetching = queryResult.fetching;
    error = queryResult.error;
  }  
  */

  /*
  const sensors_to_trigger = React.useMemo(()=>{
    let s_to_t = []
    if(row === undefined) return s_to_t;

    row.sensors_to_trigger.forEach((trig)=>{
      console.log(trig)
      if(trig.channel !== null){
        trig.output_sensors.forEach((osensor)=>{
          osensor.channel = trig.channel + 1;
          osensor.trigger_id = trig.id;
          s_to_t.push(osensor)
        })
      } else {
        trig.output_sensors.forEach((osensor)=>{
          osensor.rms = trig.rms;
          osensor.trigger_id = trig.id;
          s_to_t.push(osensor)
        })
      }
    })    
    return s_to_t;
  }, [row])


  const measurementMap = React.useMemo(()=>{
    let mmm = {}
    if(row === undefined) return mmm;

    for(var i=0; i < row.measurements.length; i++){
      var mm = row.measurements[i];
      mmm[mm.id] = mm;
    }  
    return mmm;
  }, [row])
  */

  /*
  const measurements = React.useMemo(()=>{
    
    let mmm = []

    //if(row === undefined)
    //  return mmm;

    let start = 0;
    for(var x=0; x < row.measurements.length; x++){
      let mm = row.measurements[x];
      //console.log(mm)
      mm.is_running = false;
      for(var y=start; y < row.stateupdates.length; y++){
        let sup = row.stateupdates[y];
        //console.log(`${mm.timestamp} <--> ${sup.timestamp}`)
        if(sup.timestamp < mm.timestamp){
          if(sup.rms > RMS_THRESHOLD){
            mm.is_running = true;
            start = y;
            mmm.push(mm)
          }
          break;
        }
      }
    }

    console.log(`useMemo -> measurements ${mmm}`)
    //console.log(mmm)
    return mmm;

  },[data])
  

  React.useEffect(()=>{

    if(row === undefined) return;

    let start = 0;
    for(var x=0; x < row.measurements.length; x++){
      let mm = row.measurements[x];
      //console.log(mm)
      mm.is_running = false;
      for(var y=start; y < row.stateupdates.length; y++){
        let sup = row.stateupdates[y];
        //console.log(`${mm.timestamp} <--> ${sup.timestamp}`)
        if(sup.timestamp < mm.timestamp){
          if(sup.rms > RMS_THRESHOLD){
            mm.is_running = true;
            start = y;
            //mmm.push(mm)
          }
          break;
        }
      }
    }

    //console.log(`useMemo -> measurements ${mmm}`)
    //console.log(mmm)
    //return mmm;

  },[row])  
  */

  if (fetching || stateSubResult.fetching || allResult.fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  console.log('ZSensorView->data');
  console.log(data);

  let allSensors = allResult.data.zeus_sensor.slice(0);

  row = data.zeus_sensor_by_pk  
  for(var i=0; i < row.measurements.length; i++){
    var mm = row.measurements[i];
    measurementMap[mm.id] = mm;
  }
  
  if(stateSubResult.data){
    console.log("stateSubResult.data")
    console.log(stateSubResult.data)
    stateupdate.current = stateSubResult.data.zeus_sensor_by_pk.stateupdate;
  }

  console.log('sensors_to_trigger')
  let sensors_to_trigger = []

  row.sensors_to_trigger.forEach((trig)=>{
    console.log(trig)
    if(trig.channel !== null){
      trig.output_sensors.forEach((osensor)=>{
        osensor.channel = trig.channel + 1;
        osensor.trigger_id = trig.id;
        sensors_to_trigger.push(osensor)
      })
    } else {
      trig.output_sensors.forEach((osensor)=>{
        osensor.rms = trig.rms;
        osensor.trigger_id = trig.id;
        sensors_to_trigger.push(osensor)
      })
    }
  })

  console.log("sensors_to_trigger")
  console.log(sensors_to_trigger);

  let xcolumns = [
    {
      index: 3,
      field: 'channel',
      title: 'Channel',
      headerName: 'Channel',
      minWidth: 150,
      editable: true,
      hide: false,
    },
    { 
      index: -1,
      field: 'delete', 
      title: 'Delete', 
      headerName: 'Delete',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <div>
          <strong>       
            <IconButton 
              component="span"
              //variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={(event) => { 
                let trigger_id = params.row.trigger_id;
                // now delete the trigger by id
                console.log("handleTriggerDelete->params")
                console.log(params)
                console.log(`handleTriggerDelete(${trigger_id})`)
                handleTriggerDelete(trigger_id);
              }}          
            >
              <CloseIcon />
            </IconButton>
          </strong>
        </div>
      ),
    }

  ]

  let ycolumns = [{
    index: 3,
    field: 'rms',
    title: 'RMS Threshold',
    headerName: 'RMS Threshold',
    minWidth: 150,
    editable: true,
    hide: false,
  },
  { 
    index: 4,
    field: 'delete', 
    title: 'Delete', 
    headerName: 'Delete',
    width: 100,
    editable: false,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params) => (
      <div>
        <strong>       
          <IconButton 
            component="span"
            //variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 8 }}
            onClick={(event) => { 
              let trigger_id = params.row.trigger_id;
              // now delete the trigger by id
              console.log("handleTriggerDelete->params")
              console.log(params)
              console.log(`handleTriggerDelete(${trigger_id})`)
              handleTriggerDelete(trigger_id);
            }}          
          >
            <CloseIcon />
          </IconButton>
        </strong>
      </div>
    ),
  }]  
  /////////////

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  let addNoteAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{setState({...state, showAnnotationDialog: true});}}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )  
  
  let addTicketAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showTicketDialog: true});
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )   

  let addSensorToTriggerAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          console.log("addSensorToTriggerAction->clicked")
          setState({...state, showSensorDialog: true});
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )

  let loadDataAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={(event,selectedIDs)=>{
          console.log('ZSensorView->selectedIDs')
          console.log(selectedIDs)
        }}
      >
        <CloudDownloadIcon />
      </IconButton>      
    </div>    
  )


  const handleAnalyze = (bloc) => {
    console.log("ZSensorView::handleAnalyze");
    let user_id = userData.id
    let parent_id = row.id;

    let variables = {
      serialNumber: row.name,
    }

    console.log(`selectedData`)
    console.log(selectedData)

    if(selectedData.length > 1){
      console.log(`multiple measurements selected == ${selectedData.length}`)

      variables = {
        serialNumber: row.name,
        api: "sqs",
        isensor: {
          serialNumber: row.name,
          gwSerial: row.gateway.name,
          measurementPaths: selectedData,
        },
      }

    } else {
      console.log("single measurement selected")
      //variables.apath = selectedData[0]
      //variables.api = "sqs"
      variables = {
        serialNumber: row.name,
        api: "sqs",
        isensor: {
          serialNumber: row.name,
          gwSerial: row.gateway.name,
          measurementPaths: selectedData,
        },
      }      
    }

    console.log(variables)

    //return;

    blocAnalyzeEventFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
          severity: 'error',
        })
      }else {
        console.log("blocAnalyzeEventFunction")
        console.log(result)

        let activity = {
          user_id: user_id,
          parent_id: parent_id,
          name: `analysis module ${bloc.name} triggered`,
        }

        activityFunction({object: activity})

        setAlertState({
          open: true,
          message: `${bloc.name} analysis result is ${result.data.prodigySensorAutoencode.result}`,
          severity: 'info',
        })
      }      
    })
    
    //handleClose();     
  }  


  const handleRetrain = (bloc) => {
    console.log("ZSensorView::handleRetrain");
    let user_id = userData.id;
    let parent_id = row.id;
    let variables = {} 

    variables = {
      serialNumber: row.name,
      ascending: 1,
      api: "sqs",
      iretrain: {
        serialNumber: row.name,
        gwSerial: row.gateway.name,
        measurementPaths: selectedData,
      },
    }

    if(state.startDate){
      console.log("retraining using start date and end date")
      variables.iretrain.start = moment(state.startDate).unix()
      variables.iretrain.end = moment(state.endDate).unix()
    }

    console.log(variables);   

    if(selectedData.length === 0){
      setAlertState({
        open: true,
        message: `ERROR: no measurements were selected for retraining`,
        severity: 'error',
      })

      return;
    }

    blocRetrainEventFunction(variables).then((result)=>{

      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
          severity: 'error',
        })
      }else {
        let activity = {
          user_id: user_id,
          parent_id: parent_id,
          name: `analysis module ${bloc.name} retrain`,
          description: `${userData.alias} triggered a retrain event of ${bloc.name}`,
        }

        activityFunction({activity: activity})

        setAlertState({
          open: true,
          message: `Successfully triggered retraining event`,
          severity: 'info',
        })
      }  
      
      //handleClose();
    })

  }


  let analysisBloCs = []
  if(row.client && row.analysis_blocs.length === 0){
    row.client.analysis_blocs.forEach(ab => {
      let bloc = (
        <div>
          <ZSetAnalysisBloCButton id={id} client={row.client} sensor={row} bloc={ab} active={ab.status === "enabled" ? true : false} 
            onRetrain={handleRetrain} onAnalyze={handleAnalyze} cloneable={state.cloneable} onClone={()=>setState({...state, cloneable: true})} 
            onSelectionModelSelect={handleSelectionModelSelect} selectionModel={selectionModel} 
            onSelectionAnalysisModelSelect={handleSelectionAnalysisModelSelect} selectionAnalysisModel={selectedAnalysisModel}
            noDisableButton noCloneButton noSensorList global isSensor/>
        </div>      
      )
      analysisBloCs.push(bloc)        
    });
  }

  row.analysis_blocs.forEach(ab => {
    let bloc = (
      <div>
        <ZSetAnalysisBloCButton id={id} client={row.client} sensor={row} bloc={ab} active={ab.status === "enabled" ? true : false} 
          onRetrain={handleRetrain} onAnalyze={handleAnalyze} onSelectionModelSelect={handleSelectionModelSelect} selectionModel={selectionModel} 
          onSelectionAnalysisModelSelect={handleSelectionAnalysisModelSelect} selectionAnalysisModel={selectedAnalysisModel} 
          noDisableButton noCloneButton noSensorList isSensor/>
      </div>      
    )
    analysisBloCs.push(bloc)        
  }); 


  let retrainAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={(event,selectedIDs)=>{
          console.log('ZSensorView->selectedIDs')
          console.log(selectedIDs)
        }}
      >
        <CloudDownloadIcon />
      </IconButton>      
    </div>       
  )

  console.log("updating running mode of asset")
  const findClosestUpdate = (mm) => {     
    let streamNumbers = new Set([...row.stateupdates]);
    //streamNumbers.add(mm); 
      
    // Insert the value in set and store 
    // its position 
    let it = Array.from(streamNumbers) 
      
    //it.sort(function(a, b) {return a - b}) 
    let i = it.findIndex((u)=>{return u.timestamp < mm.timestamp}); 
      
    // If x is the smallest element in set 
    if (it[i] == it[0]) { 
      // If count of elements in the set 
      // equal to 1 
      if ( it.length == 1) { 
        //process.stdout.write("-1 "); 
        return it[0]; 
      } 

      // Otherwise, print its 
      // immediate greater element 
      let rightVal = it[i + 1] 
      //process.stdout.write(rightVal + " "); 
      return rightVal; 
    } 
  
    // Store its immediate smaller element 
    let leftVal = it[i - 1] 
  
    // If immediate greater element does not 
    // exists print it's immediate 
    // smaller element 
    if (i + 1 == it.length ) { 
      //process.stdout.write(leftVal + " "); 
      return leftVal; 
    } 
  
    // Store the immediate 
    // greater element 
    let rightVal = it[i + 1]; 
  
    // Print the closest number 
    if (mm.timestamp - leftVal.timestamp <= rightVal.timestamp - mm.timestamp) 
      //process.stdout.write(leftVal + " "); 
      return leftVal; 
    else
      //process.stdout.write(rightVal + " "); 
      return rightVal;
  } 

  /*
  for(var x=0; x < row.measurements.length; x++){
    let mm = row.measurements[x];
    //console.log(mm)
    mm.is_running = false;
    let sup = findClosestUpdate(mm)
    if(sup.rms > RMS_THRESHOLD){
      mm.is_running = true;
    }
  }
  

  let start = 0;
  for(var x=0; x < row.measurements.length; x++){
    let mm = row.measurements[x];
    //console.log(mm)
    mm.is_running = false;
    for(var y=start; y < row.stateupdates.length; y++){
      let sup = row.stateupdates[y];
      //console.log(`${mm.timestamp} <--> ${sup.timestamp}`)
      if(sup.timestamp < mm.timestamp){
        if(sup.rms > RMS_THRESHOLD){
          mm.is_running = true;
          start = y;
        }
        break;
      }
    }
  }
  */

  ///////////////////

  const TopRow = () => {
    
    return (
    <Row>
      <Col xs={6}>
        <ZSensorBox id='sensor_box' 
          sensor={row}
          sensors={allSensors}
          name={row.name} 
          description={row.description} 
          annotations={row.annotations}
          refresh={executeSubscription}
          onRefresh={reexecuteQuery}
          updateMeasurements={reexecuteQuery}
          stateupdate={stateupdate.current}
          fullBox
          showImages
          static
        />
        <Row><br/></Row>
        {
          stateupdate.current.type === 3 || stateupdate.current.type === 5 || stateupdate.current.type === 6
          ?
          <ZSensorRMSPlotly sensor={row} width={1400} limit={12} /> 
          :
          <div></div>
        }        
      </Col>
      <Col xs={6}>     
       { row.activities ?
          <Card variant="outlined">
            <CardHeader
              avatar={
                <Avatar aria-label="jobboard_entry">
                  <TimelineIcon />
                </Avatar>
              }
              title='Timeline'
              titleTypographyProps={{variant:'h5'}}
            />
            <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
              <ZActivitiesTimeline size={40} items={row.activities} />
            </Paper>  
          </Card> 
          : <div/>
        }        
        <Row><br/></Row>
        <ZAttachmentsList key='docs_panel' action={<div></div>} parent={row} data={row.attachments} path={`sensor/${row.id}/attachments`} multiple static/>        
        <Row><br/></Row>
        {
          stateupdate.current.type === 3 || stateupdate.current.type === 5 || stateupdate.current.type === 6
          ?
          <ZMeasurementList key='measurement_panel' sensor={row} action={analysisBloCs} title={""} onSelectionModelSelect={handleSelectionModelSelect} selectionModel={selectionModel} 
            onSelectionAnalysisModelSelect={handleSelectionAnalysisModelSelect} selectionAnalysisModel={selectedAnalysisModel} pageSize={20} data={row.measurements} essentialCols static/>  //  waveforms realtime
          :
          <div></div>
        }     
      </Col>     
    </Row>    
  )}

  //////////////////////////
  console.log("selectedData")
  console.log(state.selectedData)

  return (
    <div>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.severity}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>    
      <Container fluid>
        <TopRow />
        <Row><br/></Row>
        {/* this is where the plotting port goes v */}
        {
          stateupdate.current.type === 3 || stateupdate.current.type === 5 || stateupdate.current.type === 6
          ?
          <Row>
            <Col xs={12}>
              <ZSensorRMSPlotly sensor={row} limit={200} width={2800}/>      
            </Col>
          </Row>   
          :
          <div></div>
        }
        <Row><br/></Row>    
        {
          stateupdate.current.type === 31
          ?
          <Row>
            <Col xs={12}>
              <ZSensorsList key='sensors_to_trigger_panel' title="Sensors To Trigger" nameW={200} aliasW={280} descriptionW={450} action={<div></div>} client={row.client ? row.client : undefined} columns={xcolumns} items={sensors_to_trigger} static/>
            </Col>
          </Row>
          :
          stateupdate.current.type === 3 || stateupdate.current.type === 5 || stateupdate.current.type === 6
          ?
          <Row>
            <Col xs={12}>
              <ZSensorsList key='sensors_to_trigger_panel' title="Sensors To Trigger" nameW={200} aliasW={280} descriptionW={450} action={<div></div>} client={row.client ? row.client : undefined} columns={ycolumns} items={sensors_to_trigger} static/>
            </Col>
          </Row>
          :
          <div></div>
        }  
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            {/* <ZAssignSensorToTriggerButton open={state.showSensorDialog} client={row} assets={row.assets} onClose={()=>setState({...state, showSensorDialog: false})} static/> */}
            <ZAddAnnotationDialog open={state.showAnnotationDialog} parent_id={row.id} handleClose={handleCloseAnnotation} />
            <ZAddTicketDialog open={state.showTicketDialog} parent={row} handleClose={()=>setState({...state, showTicketDialog: false})} />
            <Paper>
              <Box>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Notes" />
                  <Tab label="Open Tickets" />
                  <Tab label="Closed Tickets" />
                </Tabs>
              </Box>
              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <Box>
                    <ZAnnotationsList key='notes_panel' nameW={280} descriptionW={450} client={row} parent={row.asset} action={addNoteAction} items={row.annotations} static/>
                  </Box>
                )}
                {tabIndex === 1 && (
                  <Box>                   
                    <ZTicketsList key='tickets_panel' title="Open Tickets" action={addTicketAction} data={row.open_tickets} static/>
                  </Box>
                )}
                {tabIndex === 2 && (
                  <Box>                   
                    <ZTicketsList key='tickets_panel' title="Closed Tickets" action={<div></div>} data={row.closed_tickets} static/>
                  </Box>
                )}                
              </Box>              
            </Paper>
          </Col>
        </Row> 
      </Container>
    </div>
  )
}

export default ZSensorView;


//{/* <ZAttachmentsList key='docs_panel' action={<div></div>} parent={row} data={row.attachments} path={`sensor/${row.id}/attachments`} multiple static/> */}

/*

        {
          stateupdate.type === 3 || stateupdate.type === 5 || stateupdate.type === 6
          ?
          <Row>
            <Col xs={12}>
              <ZMeasurementPlotly title={row.name} data={state.selectedData ? state.selectedData[0].values : [[],[]]} />      
            </Col>
          </Row>   
          :
          <div></div>
        }  
*/


/*

              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <Box>
                    <ZAnnotationsList key='notes_panel' nameW={280} descriptionW={450} client={row} parent={row.locker} action={addNoteAction} items={row.annotations} static/>
                  </Box>
                )}
                {tabIndex === 1 && (
                  <Box>                   
                    <ZTicketsList key='tickets_panel' title="Open Tickets" action={addTicketAction} data={row.open_tickets} static/>
                  </Box>
                )}
                {tabIndex === 2 && (
                  <Box>                   
                    <ZTicketsList key='tickets_panel' title="Closed Tickets" action={<div></div>} data={row.closed_tickets} static/>
                  </Box>
                )}                
              </Box>


      <Col xs={6}>     
        <ZMeasurementList key='measurement_panel' sensor={row} data={row.measurements}  static/>
      </Col>

        <Row><br/></Row>
          <Card variant="outlined">
            <CardHeader
              avatar={
                <Avatar aria-label="sensor_images" className={classes.image_avatar}>
                  <PhotoLibraryIcon />
                </Avatar>
              }
              title='Images'
              titleTypographyProps={{variant:'h5'}}
            />
            <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
              <ZImageListHorizontal key='image_list' parent_id={row.id} path={`sensor/${row.id}/images`} data={row.images} /> 
            </Paper>  
          </Card> 


*/

