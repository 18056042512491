import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { red, green, cyan, purple, lightBlue, orange, yellow } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useQuery, useMutation } from '@apollo/client';
import { getRoutesForClient } from 'queries';
import { addRoute, updateRoute } from 'mutations';
*/
import { useQuery, useMutation, useSubscription } from 'urql';
import { getSensorsForGateway } from 'queries_urql';
import { subscribeToolKitsForNode } from 'subscriptions_urql';
import { updateTrigger, addNotification } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu'; //Menu
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BallotIcon from '@material-ui/icons/Ballot'; //BallotIcon
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import IconButton from '@material-ui/core/IconButton';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import ButtonGroup from '@material-ui/core/ButtonGroup'; //ButtonGroup
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Fab from '@material-ui/core/Fab'; //Fab
import Avatar from '@material-ui/core/Avatar';
import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import Moment from 'react-moment';
import moment from 'moment';

import { SettingsRemoteRounded } from '@material-ui/icons';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import NextWeekIcon from '@material-ui/icons/NextWeek';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import ReactTooltip from 'react-tooltip';

import { Callout, Link, mergeStyleSets, Text, FontWeights } from '@fluentui/react';

import ZStatusEditInputCell from 'components/core/ZStatusEditInputCell';
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import ZBookToolDialog from 'components/dialogs/ZBookToolDialog';
import ZFluentCalendar from 'components/core/ZFluentCalendar';
import ZTriggerTimingButton from 'components/buttons/ZTriggerTimingButton'; //ZTriggerTimingButton
import ZTriggerReportButton from 'components/buttons/ZTriggerReportButton';//ZTriggerReportButton

//////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    //justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    //display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  sensor_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.active': {
      backgroundColor: lightBlue[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },    
    '& .super-app.non_responsive': {
      backgroundColor: orange[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.recovered': {
      backgroundColor: yellow[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.lost': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.low_battery': {
      backgroundColor: purple[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  routes_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //red
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: lightBlue[100],   //green
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

//////////////////////////////////////////////////////////////////////

/*
props : {
  parent: {}

}
*/

const styles = mergeStyleSets();

//////////////////////////////////////////////////////////////////////

const ZSensorTriggerList = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const TRIGGER_DELAY_THRESHOLD = 20; // 20 minutes trigger delay threshold before flagged as a failed trigger

  //const [routeId, storeRouteId] = useLocalStorage('route_id', '');
  const [userData, setUserData] = useLocalStorage('userData');
  //const [userRoles, setUserRoles] = useLocalStorage('userRoles');

  const [anchorHeader, setAnchorHeader] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
    serverity: 'info',
    error: false,
  });

  const [state, setState] = React.useState({
    activeEntry: undefined,
    waitUnits: 60 * 60,
    waitUnitsTxt: 'Hours',
    waitFor: 3
  });
  
  //////////////

  let rows = []

  const renderTriggerWaitEditInputCell = (params) => {
    return (
      <Paper component="form" className={classes.timeUnits}>
        <TextField
          id="name-control"
          style={{ width: 500 }}
          error={Number.isSafeInteger(state.waitFor)}
          fullWidth
          defaultValue={state.waitFor}
          label="Time to ignore trigger after collection"
          onChange={(event) =>{
            setState({...state, waitFor: event.target.value});
          }} 
        />  
        <Button aria-controls="time-units-menu" aria-haspopup="true" 
          onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}>
          {state.waitUnitsTxt}
        </Button>
        <Menu
          id="time-units-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={()=>{
            setAnchorEl(null)
          }}
        >
          <MenuItem onClick={()=>{setState(({...state, waitUnits: 60, waitUnitsTxt: 'Minutes'}));setAnchorEl(null)}}>Minutes</MenuItem>
          <MenuItem onClick={()=>{setState(({...state, waitUnits: 60 * 60, waitUnitsTxt: 'Hours'}));setAnchorEl(null)}}>Hours</MenuItem>
          <MenuItem onClick={()=>{setState(({...state, waitUnits: 60 * 60 * 24, waitUnitsTxt: 'Days'}));setAnchorEl(null)}}>Days</MenuItem>
          <MenuItem onClick={()=>{setState(({...state, waitUnits: 60 * 60 * 24 * 7, waitUnitsTxt: 'Weeks'}));setAnchorEl(null)}}>Weeks</MenuItem>
          <MenuItem onClick={()=>{setState(({...state, waitUnits: 60 * 60 * 24 * 30, waitUnitsTxt: 'Months'}));setAnchorEl(null)}}>Months</MenuItem>
        </Menu>
      </Paper>      
    )
  }

  const renderChannelEditInputCell = (params) => {
    return (
      <Select
        labelId="channel-select-label"
        id="channel-select"
        onChange={(event)=>setState({...state, channel: event.target.value})}
      >
        <MenuItem value={0}>One</MenuItem>
        <MenuItem value={1}>Two</MenuItem>
        <MenuItem value={2}>Three</MenuItem>
        <MenuItem value={3}>Four</MenuItem>
      </Select> 
    );
  }


  const renderStatusEditInputCell = (params) => {
    return (
      <ZStatusEditInputCell {...params} >
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem value="active">active</MenuItem>
      </ZStatusEditInputCell>
    );
  }


  const renderTriggerOnEditInputCell = (params) => {
    return (
      <Select
        labelId="trigger-on-select-label"
        id="trigger-on-select"
        onChange={(event)=>setState({...state, triggerOnValue: event.target.value})}
      >
        <MenuItem value={0}>Close</MenuItem>
        <MenuItem value={1}>Open</MenuItem>
      </Select> 
    );
  }


  const handleCardHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = () => {
    setAnchorHeader(null);
  }


  let columns = [
    {
      field: 'name',
      title: 'Name',
      headerName: 'Name',
      minWidth: props.nameW ?? 300,
      editable: true,
      hide: false,
    },
    { 
      field: 'inputsensor', 
      title: 'Triggering Sensor', 
      headerName: 'Triggering Sensor',
      width: 200,
      editable: false,
      sortable: true,
      renderCell: (params) => (
        <div>
          <strong>
          <ButtonGroup size="small">
            <div>        
              <IconButton 
                component="span"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={()=>window.open(`https://zeus.primac.app/sensor/${params.row.input_sensor.id}`, "newwindow")}         
              >
                {params.row.input_sensor.name}
              </IconButton>
            </div>
          </ButtonGroup>
          </strong>
        </div>
      ),
      valueGetter: (params) => {
        return params.row.input_sensor.name !== null ? params.row.input_sensor.name : 'N/A'
      },       
    },
    {
      field: 'input_alias',
      title: 'Alias',
      headerName: 'Alias',
      minWidth: 300,
      editable: false,   
      sortable: true,
      renderCell: (params) => (<div>{params.row.input_sensor.alias !== null ? params.row.input_sensor.alias : 'N/A'}</div>),
      valueGetter: (params) => {
        return params.row.input_sensor.alias !== null ? params.row.input_sensor.alias : 'N/A'
      },
    },   
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      renderEditCell: renderStatusEditInputCell,
      minWidth: 150,
      editable: true,   
      sortable: false,
      renderCell: (params) => {
        if(params.row.status === "active"){
          return (<div>&#9193;</div>)
        } else {
          return (<div>&#9940;</div>)
        }
      },
    },       
    { 
      field: 'outputsensor', 
      title: 'Triggered Sensor', 
      headerName: 'Triggered Sensor',
      width: 200,
      editable: false,
      sortable: true,
      renderCell: (params) => (
        <div>
          <strong>
          <ButtonGroup size="small">
            <div>        
              <IconButton 
                component="span"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={()=>window.open(`https://zeus.primac.app/sensor/${params.row.output_sensor.id}`, "newwindow")}         
              >
                {params.row.output_sensor.name}
              </IconButton>
            </div>
          </ButtonGroup>
          </strong>
        </div>
      ),
      valueGetter: (params) => {
        return params.row.output_sensor.name !== null ? params.row.output_sensor.name : 'N/A'
      },        
    },    
    {
      field: 'output_alias',
      title: 'Alias',
      headerName: 'Alias',
      minWidth: 300,
      editable: false,   
      sortable: true,
      renderCell: (params) => (<div>{params.row.output_sensor.alias !== null ? params.row.output_sensor.alias : 'N/A'}</div>),
      valueGetter: (params) => {
        return params.row.output_sensor.alias !== null ? params.row.output_sensor.alias : 'N/A'
      },
    },        
    {
      field: 'channel',
      title: 'Channel',
      headerName: 'Channel',
      minWidth: 150,
      editable: true,   
      sortable: true,
      renderEditCell: renderChannelEditInputCell,
      renderCell: (params) => (<div>{params.row.channel + 1}</div>),
    },  
    {
      field: 'triggerOnValue',
      title: 'Trigger On',
      headerName: 'Trigger On',
      minWidth: 200,
      editable: true,   
      sortable: true,
      renderEditCell: renderTriggerOnEditInputCell,
      renderCell: (params) => (<div>{params.row.triggerOnValue === false ? 'Close' : 'Open'}</div>),
      valueGetter: (params) => {
        return params.row.triggerOnValue === false ? 0 : 1
      },      
    },          
    {
      field: 'waitFor',
      title: 'Trigger Ignore (sec)',
      headerName: 'Trigger Ignore (sec)',
      minWidth: 200,
      editable: true,   
      sortable: true,
      //renderEditCell: renderTriggerWaitEditInputCell
    },   
    {
      field: 'lastTriggered',
      title: 'Last Triggered',
      headerName: 'Last Triggered',
      type: 'date',
      minWidth: 200,
      editable: false,
      renderCell: (params) => {
        if(params.row.lastTriggered === 0){
          return (<div>N/A</div>)
        }

        return (
          <Moment format="YYYY-MM-DD HH:mm:ss">
            {moment.unix(params.row.lastTriggered).format("YYYY-MM-DD HH:mm:ss")}
          </Moment>
        )
      },
    },
    {
      field: 'lastMeasurement',
      title: 'Last Collection',
      headerName: 'Last Collection',
      type: 'date',
      minWidth: 200,
      editable: false,
      renderCell: (params) => {
        if(params.row.output_sensor.lastMeasurement === 0 || params.row.output_sensor.lastMeasurement === ''){
          return (<div>N/A</div>)
        }

        return (
          <div>
            {moment(params.row.output_sensor.lastMeasurement).format("YYYY-MM-DD HH:mm:ss")}
          </div>
        )
      },
    },              
    {
      field: 'createdOn',
      title: 'Date Created',
      headerName: 'Date Created',
      type: 'date',
      minWidth: 200,
      editable: false,
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      ),
    }, 
  ];

  //////////////////
  console.log('ZSensorTriggerList->props')
  console.log(props)

  if(props.recolumns){
    if(props.recolumns.hasOwnProperty('index')){
      var index = props.recolumns.index;
      columns[index] = props.recolumns;
      console.log('column replaced')
    }    
  }

  if(props.columns){
    if(Array.isArray(props.columns)){
      columns = columns.concat(props.columns);
      console.log('list of columns added')
    } else {
      if(props.columns.hasOwnProperty('index')){
        let index = props.columns.index;
        columns.splice(index, 0, props.columns);
        console.log('single column added')
      }
    }
  }


  if(props.data){
    console.log('ZSensorTriggerList->data')
    rows = props.data
  }

  if(props.items){
    console.log('ZSensorTriggerList->items')
    rows = props.items
  }

  console.log(rows)

  console.log('look for sensors not responding to triggers');
  rows.forEach((rrr)=>{
    let lastT = moment.unix(rrr.lastTriggered);
    let lastM = moment(rrr.output_sensor.lastMeasurement);
    if(Math.abs(lastT.diff(lastM, 'minutes')) > TRIGGER_DELAY_THRESHOLD){
      console.log(`failed trigger ${rrr.name} - ${rrr.output_sensor.alias} a delay of more than 20 minutes was detected`)
      rrr.failed_trigger = true;
    } else {
      rrr.failed_trigger = false;
    }
  })
  
  const [updateTriggerResult, updateFunction] = useMutation(updateTrigger);
  const [addNotificationResult, insertNotificationFunction]   = useMutation(addNotification);

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZSensorTriggerList::handleCellEditCommit');
      console.log(id, field, value)

      let user_id = userData.id;

      var _set = {}
      _set[field] = value

      const modifiedOn = moment().format();
      _set.modifiedOn = modifiedOn;

      let variables = {
        id: id,
        _set: _set,
        activities: [{
            user_id: user_id,
            parent_id: id,
            name: `sensor trigger ${field} updated`,
            description: `${userData.alias} updated sensor trigger ${field} to ${value}`,          
          },
          {
            user_id: user_id,
            parent_id: props.client.id,
            name: `sensor trigger ${field} updated`,
            description: `${userData.alias} updated sensor trigger ${field} to ${value}`,          
          },        
        ]
      }

      console.log('ZSensorTriggerList->variables')
      console.log(variables)

      updateFunction( variables ).then((result)=>{
        if(result.error){
          console.log(result.error)
          setAlertState({...alertState, message: `ERROR: ${result.error}`, severity: 'error', open: true})

        } else {
          console.log("successfully updated sensor trigger")

          // send a notification if status was updated
          if(field === 'status'){

            var trigger = rows.find((sn)=>{
              return sn.id === id;
            })

            insertNotificationFunction({object: {
              name: `Sensor Triger ${trigger.name} status was updated`,
              description: `${userData.alias} updated sensor trigger ${trigger.name} updating status to ${value}`,
              role: 'manager',
            }});

          }

          setAlertState({...alertState, message: "successfully updated sensor trigger", severity: 'info', open: true})
        }
      })
    },
  []);


  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorHeader}
        keepMounted
        open={Boolean(anchorHeader)}
        onClose={handleCloseHeader}
      >
        <MenuItem onClick={handleCloseHeader}>New Note</MenuItem>
      </Menu>
    </div>  
  )


  let defaultAction = (
    <Fab
      //color="secondary"
      aria-label="add"
      size="small"
      onClick={handleCardHeaderClick}
      data-tip="sensor trigger actions"
      data-for="sensor_trigger_tooltip" 
    >
      <MoreVertIcon />
      {headerMenu}
    </Fab>
  )

  ////////////////////
  
  return (
    <div>
      <Card variant="outlined" className={classes.root}>
        <ZTriggerTimingButton />
        <ZTriggerReportButton />
        <CardHeader
          avatar={
            <Avatar aria-label="triggers" className={classes.routes_avatar}>
              <NetworkCheckIcon />
            </Avatar>
          }      
          action={props.action ? props.action : defaultAction}         
          title={props.title ? props.title : 'Sensor Triggers'}
          titleTypographyProps={{variant:'h5'}}
        />
        <CardContent>
          <Paper id='sensor-trigger-data-grid' className={classes.sensor_row}>
            <DataGrid autoHeight
              rows={rows}
              columns={columns}
              pageSize={props.pageSize ?? 20}
              rowsPerPageOptions={[5]}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              getRowClassName={(params) =>
                clsx('super-app', {
                  active: params.row.status === 'active' && params.row.failed_trigger === false,
                  negative: params.row.status === 'active' && params.row.failed_trigger === true,
                })
              }
              options={{
                toolbar: true,
                filtering: true,
                search: true,
                grouping: true,
                exportButton: true,
              }}
              components={{
                Toolbar: GridToolbar,
              }}
              onCellEditCommit={props.editable ? handleCellEditCommit : ()=>{}}
            />
          </Paper>
        </CardContent>
        <ReactTooltip id="sensor_trigger_tooltip"/>
      </Card>
    </div>
  ); 
}
  
export default ZSensorTriggerList;
