import React from 'react';
import clsx from 'clsx';
import { makeStyles, styled } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { zeusClientTriggerReport, addNotification } from 'mutations_urql';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select'; //FormControl
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu'; //Menu
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import { uuid } from 'uuidv4';
import moment from 'moment';
import ZSensorAutocomplete from 'components/core/ZSensorAutocomplete';
import { CheckBox, StarRateOutlined } from '@material-ui/icons';


// ($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $parent_id: uuid = "")
////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(5),
    },    
  },
  timeUnits: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  snackbarStyle: {
    backgroundColor: "lightyellow"
  },
  snackbarStyleViaNestedContent: {
    backgroundColor: "lightgreen",
    color: "black"
  },   
}));

//////////////////////

const ZFormControlLabel = styled(FormControlLabel)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  
});

//////////////////////////////////////////////////

const ZTriggerReportButton = (props) => {
    
  const classes = useStyles();
  const history = useHistory();

  const [userData, setUserData] = useLocalStorage('userData');
  const [anchorEl, setAnchorEl] = React.useState(null);

  // default wait units is seconds
  const [state, setState] = React.useState({
    open: false,
    sensor: undefined,
    name: "Dry Contact Sensor Trigger",
    description: null,
    channel: 0,
    triggerOnValue: false,
    waitUnitsTxt: 'Hours',
    waitUnits: 60 * 60, //  hours
    waitFor: 3, // 3 hours
    dailyWaitFor: false,
    lastTriggered: 0,
    status: 'active',
  });

  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const [reportTriggerResult,     reportTriggerFunction]     = useMutation(zeusClientTriggerReport);
  const [addNotificationResult, insertNotificationFunction] = useMutation(addNotification);

  const handleClose = () => {
    setState({...state, open: false})
    if(props.onClose){
      props.onClose();
    }
  }

  const handleSensorChange = (event,item) => {
    console.log('setting sensor')
    console.log(item)
    setState({...state, sensor_id: item.id, sensor: item})
  }

  const handleChannelChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  }

  const handleNotificationChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  }

  const handleDailyWaitForChange = (event) => {
    setState({ ...state, waitFor: 0, waitUnits: 0, [event.target.name]: event.target.checked });
  }

  let client_id = null;
  if(props.client){
    client_id = props.client.id
  }


  const handleConfirm = () => {

    let variables = { 
      client_id: client_id,
    }

    console.log(variables)

    reportTriggerFunction( variables ).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          open: true,
          message: `ERROR: ${result.error}`,
          severity: 'error',
        })
      }else {
        console.log('ZTriggerReportButton::reportTriggerFunction')
        console.log(result); 
                
        setAlertState({
          open: true,
          message: 'Successfully requested Trigger Report',
          severity: 'info',
        })
      }
    })
  }

  ////////////////

  return (
    <div>
      <strong>
      <Container maxWidth="lg">
        <Box>
          <Dialog open={state.open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
            <DialogTitle id="form-dialog-title">Request Trigger Report</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
              <Button onClick={handleConfirm} color="primary">
                Request
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        <Box>
          <Collapse in={alertState.open}>
            <Alert severity={alertState.severity}>
              <AlertTitle>Alert</AlertTitle>
              <strong>{alertState.message}</strong>
            </Alert>  
          </Collapse>
        </Box>
      </Container>
      </strong>
      <Button size="large" color="primary"
        id={props.id}
        style={{ marginLeft: 8 }}
        startIcon={ props.done ? <CheckIcon color="secondary"/> : <div/>}
        onClick={(event) => { 
          setState({...state, open: true});
          if(props.togglePause){
            props.togglePause();
          }         
        }}       
        disabled={props.disabled}              
      >
        Create Trigger Report
      </Button>
    </div>
  )
}

export default ZTriggerReportButton;

