import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, cyan, blue } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getToolNode,  getAllToolsForNode } from 'queries_urql';
import { zeusSensorCollectWaveform, zeusSensorStateUpdate, zeusSensorCloseContact, addNotification } from 'mutations_urql';

import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from "@material-ui/core/Collapse";

import { uuid } from 'uuidv4';
import moment from 'moment';


// ($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $parent_id: uuid = "")
////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(5),
    },    
  },
  snackbarStyle: {
    backgroundColor: "lightyellow"
  },
}));

//////////////////////

const ZSensorGatewayCommandsButton = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [userData, setUserData] = useLocalStorage('userData');
  const [state, setState] = React.useState({
    open: false,
    completedOn: '',
  });
  const [alertState, setAlertState] = React.useState({
    level: 'info',
    open: false,
    message: '',
  });


  console.log('ZSensorGatewayCommandsButton->props')
  console.log(props)

  const [collectWaveformResult,     collectWaveformFunction]            = useMutation(zeusSensorCollectWaveform);
  const [closeContactResult,        closeContactFunction]               = useMutation(zeusSensorCloseContact);
  const [stateUpdateResult,         stateUpdateFunction]                    = useMutation(zeusSensorStateUpdate);
  
  const [addNotificationResult, insertNotificationFunction] = useMutation(addNotification);


  const handleClose = () => {
    setState({...state, open: false})
  }

  let sensor_id = null;
  if(props.sensor){
    sensor_id = props.sensor.id
  }


  const handleCollectWaveform = () => {
    console.log("ZSensorGatewayCommandsButton -> handleCollectWaveform")
    let user_id = userData.id

    let variables = { 
      userId: user_id,
      userName: userData.alias,
      serialNumber: props.sensor.name,
    }

    console.log(variables)

    collectWaveformFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          level: 'error',
          open: true,
          message: `ERROR: ${result.error}`,
        })
      }else {
        setAlertState({
          level: 'info',
          open: true,
          message: 'Successfully sent command to sensor to collect waveform',
        })
      }

    })
  }


  const handleCloseContact= (channel) => {
    console.log(`ZSensorGatewayCommandsButton -> handleCloseContact(channel=${channel})`)
    let user_id = userData.id

    let variables = { 
      userId: user_id,
      userName: userData.alias,
      serialNumber: props.sensor.name,
      channel:channel,
    }

    console.log(variables)

    closeContactFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          level: 'error',
          open: true,
          message: `ERROR: ${result.error}`,
        })
      }else {
        setAlertState({
          level: 'info',
          open: true,
          message: `Successfully sent command to sensor to close contact on channel - ${channel}`,
        })
      }

    })
  }


  const handleStateUpdate = () => {
    console.log("ZSensorGatewayCommandsButton -> handleStateUpdate")
    let user_id = userData.id

    let variables = { 
      userId: user_id,
      userName: userData.alias,
      serialNumber: props.sensor.name,
    }

    console.log(variables)

    stateUpdateFunction(variables).then((result)=>{
      if(result.error){
        console.log(`ERROR: ${result.error}`)
        setAlertState({
          level: 'error',
          open: true,
          message: `ERROR: ${result.error}`,
        })
      }else {
        setAlertState({
          level: 'info',
          open: true,
          message: 'Successfully sent command to sensor to return state update',
        })
      }

    })
  }

  ////////////////

  return (
    <div>
      <Collapse in={alertState.open}>
        <Alert severity={alertState.level}>
          <AlertTitle>Alert</AlertTitle>
          <strong>{alertState.message}</strong>
        </Alert>  
      </Collapse>
      <strong>
        <Router>
          <div>
            <Snackbar
              anchorOrigin={{ vertical:'top', horizontal:'center' }}
              autoHideDuration={12000}
              open={state.open}
              key="set_job_closed"
              onClose={()=>{
                setState({...state, open: false})
              }}
              ContentProps={{
                "aria-describedby": "message-id",
                className: classes.snackbarStyle
              }}
              action={
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  {
                    props.stateupdate.type === 31
                    ?
                      <div>
                        <Button className={classes.button} color="default" variant="contained" onClick={()=>handleCloseContact(0)}>
                          Close Channel-1
                        </Button>
                        <Button className={classes.button} color="default" variant="contained" onClick={()=>handleCloseContact(1)}>
                          Close Channel-2
                        </Button>
                        <Button className={classes.button} color="default" variant="contained" onClick={()=>handleCloseContact(2)}>
                          Close Channel-3
                        </Button>
                        <Button className={classes.button} color="default" variant="contained" onClick={()=>handleCloseContact(3)}>
                          Close Channel-4
                        </Button>                                                                        
                      </div>
                    :
                      <Button className={classes.button} color="default" variant="contained" onClick={handleCollectWaveform}>
                        Collect Waveform
                      </Button>
                  }
                  <Button className={classes.button} color="default" variant="contained" onClick={handleStateUpdate}>
                    State Update
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<CloseIcon />}
                    onClick={()=>{
                      setState({...state, open: false})
                    }}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              }
            />
            <Button size="large" color="primary"
              id={props.id}
              style={{ marginLeft: 8 }}
              startIcon={ props.done ? <CheckIcon color="secondary" /> : <div/>}
              onClick={(event) => { 
                setState({...state, open: true})
              }}       
              disabled={props.disabled}               
            >
              Sensor Commands
            </Button>
          </div>
        </Router>
      </strong>
    </div>
  )
}

export default ZSensorGatewayCommandsButton;