/*


-----------

drop function zeus.get_new_job_number(text);

create or replace function zeus.get_new_job_number(prefix text)
returns text as $$
job_num = (select prefix || A.nextval from (select nextval('zeus.jobboard_entry_job_integer_seq')) as A) $$
return job_num
language SQL volatile

---------

DROP FUNCTION zeus.get_new_job_number(text);

CREATE OR REPLACE FUNCTION zeus.get_new_job_number(prefix text)
RETURNS SETOF articles AS $job_number$
DECLARE
	job_number text;
BEGIN
  SELECT prefix || A.nextval INTO job_number FROM (SELECT nextval('zeus.jobboard_entry_job_integer_seq')) AS A;
  RETURN job_number;
END;
$job_number$ LANGUAGE plpgsql;

SELECT zeus.get_new_job_number('CP');

---------

DROP FUNCTION zeus.create_new_job_board_entry(text);

CREATE OR REPLACE FUNCTION zeus.create_new_job_board_entry(prefix text)
RETURNS text AS $jobboard_entry$
DECLARE
	job_number text;
BEGIN
  SELECT prefix || A.nextval INTO job_number FROM (SELECT nextval('zeus.jobboard_entry_job_integer_seq')) AS A;
  RETURN jobboard_entry;
END;
$jobboard_entry$ LANGUAGE plpgsql;

===========================

DROP FUNCTION zeus.create_new_job_number();

CREATE OR REPLACE FUNCTION zeus.create_new_job_number() RETURNS trigger AS $$
  DECLARE
    prefix text;
    v_job record;
  BEGIN
    prefix = "C";
    SELECT INTO v_job * FROM zeus.jobboard_entry INTO v_job ORDER BY createdOn DESC LIMIT 1;
    
    IF v_job.isService = 1 AND v_job.isProduct = 1 THEN
      prefix = prefix || "(P)"
    ELSEIF v_job.isService = 0 AND v_job.isProduct = 1 THEN
      prefix = prefix || "P"
    END IF;

    job_name = prefix || suffix;
    UPDATE zeus.jobboard_entry set name = job_name WHERE uuid = v_job.uuid;
  END;
$$ LANGUAGE plpgsql;

--

CREATE TRIGGER job_number_trigger AFTER INSERT ON zeus.jobboard_entry
      FOR EACH ROW EXECUTE PROCEDURE zeus.create_new_job_number();

============================
select zeus.create_new_job_board_entry('CP')

============================

DROP FUNCTION zeus.get_new_job_number(text);

-----

CREATE OR REPLACE FUNCTION zeus.get_new_job_number(prefix text)
RETURNS TABLE zeus.sql_function_table AS $$
  SELECT prefix || A.nextval FROM (SELECT nextval('zeus.jobboard_entry_job_integer_seq')) AS A;
$$ LANGUAGE SQL;

----
select zeus.get_new_job_number('CP')
*/


export const updateCashFlowEntryField = `

`;


export const prodigyClientAutoencode = `
mutation prodigyClientAutoencode($id: String = "") {
  prodigyClientAutoencode(clientId: $id) {
    id
    name
    status
    sensors {
      serialNumber
      gwSerial
      timestamp
    }
  }
}
`;

export const prodigyClientRetrain = `
mutation prodigyClientRetrain($client_id: String = "") {
  prodigyClientRetrain(client_id: $client_id) {
    id
    name
    status
    sensors {
      serialNumber
      gwSerial
      timestamp
    }
  }
}
`;


export const prodigySensorAutoencode = `
mutation prodigySensorAutoencode($serialNumber: String = "", $apath: String = "", $timestamp: Int, $start: Int, $end: Int, $api: String = "sqs", $isensor: ISensor = {}) {
  prodigySensorAutoencode(serialNumber: $serialNumber, apath: $apath, timestamp: $timestamp, api: $api, start: $start, end: $end, isensor: $isensor) {
    serialNumber
    gwSerial
    timestamp
    result
  }
}
`;


export const prodigySensorRetrain = `
mutation prodigySensorRetrain($serialNumber: String = "", $ascending: Int = 1, $api: String = "sqs", $period: Int = 28) {
  prodigySensorRetrain(serialNumber: $serialNumber, ascending: $ascending, period: $period, api: "sqs") {
    serialNumber
    timestamp
    period
  }
}
`;


export const prodigySensorRetrainI = `
mutation prodigySensorRetrain($serialNumber: String = "", $ascending: Int = 1, $period: Int = 28, $api: String = "sqs", $iretrain: IRetrain = {}) {
  prodigySensorRetrain(serialNumber: $serialNumber, ascending: $ascending, period: $period, api: $api, iretrain: $iretrain) {
    serialNumber
    timestamp
    period
  }
}
`;


export const zeusSensorCollectWaveform = `
mutation zeusSensorCollectWaveform($serialNumber: String = "", $userId: String = "", $userName: String = "") {
  zeusSensorCollectWaveform(serialNumber: $serialNumber, userId: $userId, userName: $userName){
    name
    timestamp
  }
}
`;

export const zeusSensorCloseContact = `
mutation zeusSensorCloseContact($serialNumber: String = "", $userId: String = "", $userName: String = "", $channel: Int = 0) {
  zeusSensorCloseContact(serialNumber: $serialNumber, userId: $userId, userName: $userName, $channel){
    name
    timestamp
  }
}
`;


export const zeusSensorStateUpdate = `
mutation zeusSensorStateUpdate($serialNumber: String = "", $userId: String = "", $userName: String = "") {
  zeusSensorStateUpdate(serialNumber: $serialNumber, userId: $userId, userName: $userName){
    name
    timestamp
  }
}
`;


export const zeusGatewayGetState = `
mutation zeusGatewayGetState($serialNumber: String = "", $userId: String = "", $userName: String = "") {
  zeusGatewayGetState(serialNumber: $serialNumber, userId: $userId, userName: $userName){
    name
    timestamp
  }
}
`;

export const zeusGatewaySendState = `
mutation zeusGatewaySendState($serialNumber: String = "", $userId: String = "", $userName: String = "") {
  zeusGatewaySendState(serialNumber: $serialNumber, userId: $userId, userName: $userName){
    name
    timestamp
  }
}
`;


export const getUserLogin = `
mutation getUserLogin {
  userLogin($username: String = "", $password: String = "") {
    __typename
    id
    email
    firstName
    lastName
    status
    globalPermissions
    accessToken
    alias
  }
}
`;


export const refreshMutation = `
mutation refreshMutation {
  user($username: String = "", $password: String = "") {
    __typename
    id
    email
    firstName
    lastName
    status
    globalPermissions
    accessToken
  }
}
`;


export const updateComponent = `
mutation updateComponent($id: uuid = "", $_set: zeus_component_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_component_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
    name
    description
    modifiedOn
  }
  insert_zeus_activity_one(object: $activity) {
    id
    name
    description
  }
}
`;


export const updateSalesDocumentField = `
mutation updateSalesDocument($user_id: uuid!, $id: uuid!, $_set: zeus_sales_document_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_sales_document_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    document_type
    createdOn
    amount
    status
    jobBoardEntry {
      __typename
      id
      name
      description
      status
    }
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {document_id: $id, description: "updated sales document" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;

export const updateSensor = `
mutation updateSensor($id: uuid = "", $_set: zeus_sensor_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_sensor_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    alias
    description
    createdOn
    modifiedOn
    serialNumber
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  } 
}
`;


export const updateSensorField = `
mutation updateSensorField($id: uuid = "", $_set: zeus_sensor_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_sensor_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    alias
    description
    createdOn
    modifiedOn
    serialNumber
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
}
`;


export const zeusUpdateSensorAlias = `
mutation zeusUpdateSensorAlias($serialNumber: String = "", $userId: String = "", $userName: String = "", $alias: String = "") {
  zeusUpdateSensorAlias(serialNumber: $serialNumber, userId: $userId, userName: $userName, alias: $alias){
    name
    timestamp
  }
}
`;


export const zeusClientTriggerReport = `
mutation zeusClientTriggerReport($clientId: String = "") {
  zeusClientTriggerReport(clientId: $clientId){
    id
    name
    data
  }
}
`;


export const updateComponentField = `
mutation updateComponentField($id: uuid = "", $_set: zeus_component_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_component_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
}
`;


export const updateUserField = `
mutation updateUserField($id: uuid = "", $_set: zeus_zeus_user_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_zeus_user_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    firstName
    lastName
    email
    global_permissions
    alias
    description
    createdOn
    modifiedOn
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
}
`;


export const updateUserRole = `
mutation updateUserRole($id: uuid = "", $_set: zeus_user_to_role_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_user_to_role_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
    is_default
    role_id
    status
    user_id
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
}
`;


export const updateGateway = `
mutation updateGateway($id: uuid = "", $_set: zeus_gateway_set_input = {}) {
  update_zeus_gateway_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    alias
    description
    createdOn
    modifiedOn
    status
  }
}
`;


export const updateSalesDocument = `
mutation updateSalesDocument($user_id: uuid!, $id: uuid!, $_set: zeus_sales_document_set_input = {}) {
  update_zeus_sales_document_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    document_type
    createdOn
    amount
    status
    jobBoardEntry {
      __typename
      id
      name
      description
      status
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {document_id: $id, description: "updated sales document" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;

export const addActivity = `
mutation addActivity($object: zeus_activity_insert_input = {}) {
  insert_zeus_activity_one(object: $object) {
    __typename
    id
    name
    description
    status
    createdOn
    closedOn
  }
}
`;


export const addAttachment = `
mutation addAttachment($user_id: uuid = "", $id: uuid = "", $object: zeus_attachment_insert_input = {}) {
  insert_zeus_attachment_one(object: $object) {
    __typename
    id
    name
    path
    description
  }
  insert_zeus_activity_one(object: {user_id: $user_id, parent_id: $id, name: "added attachment", description: "added new attachment"}) {
    __typename
    id
    name
    description
    createdOn
  }  
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {attachment_id: $id, description: "created new attachment" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const addComponent = `
mutation addComponent($object: zeus_component_insert_input = {}, $activities: [zeus_activity_insert_input!] = {}) {
  insert_zeus_component_one(object: $object) {
    __typename
    id
    name
    description
  }
  insert_zeus_activity(objects: $activities) {
    affected_rows
  }  
}
`;


export const addLocations = `
mutation addLocations($objects: [zeus_component_location_insert_input!] = {}, $activities: [zeus_activity_insert_input!] = {}) {
  insert_zeus_component_location(objects: $objects) {
    affected_rows
  }
  insert_zeus_activity(objects: $activities) {
    affected_rows
  }
}
`;



export const addAttachments = `
mutation addAttachments($user_id: uuid = "", $objects: [zeus_attachment_insert_input!] = {}, $activities: [zeus_activity_insert_input!] = {}) {
  insert_zeus_attachment(objects: $objects) {
    returning{
      __typename
      id
      name
      path
      description
    }
  }
  insert_zeus_activity(objects: $activities) {
    returning {
      __typename
      id
      name
      description
      createdOn
    }
  }  
}
`;

export const addAsset = `
mutation addAsset($object: zeus_asset_insert_input = {}, $activities: [zeus_activity_insert_input!] = {}) {
  insert_zeus_asset_one(object: $object) {
    __typename
    id
    name
    description
    createdOn
    status
  }
  insert_zeus_activity(objects: $activities) {
    returning {
      __typename
      id
      name
      description
      createdOn
    }
  }  
}
`;


export const addAnalysisBloC = `
mutation addAnalysisBloC($object: zeus_analysis_bloc_insert_input = {}, $activities: [zeus_activity_insert_input!] = {}) {
  insert_zeus_analysis_bloc_one(object: $object) {
    __typename
    id
    name
    description
    parent_id
    createdOn
    status
  }
  insert_zeus_activity(objects: $activities) {
    returning {
      __typename
      id
      name
      description
      createdOn
    }
  }  
}
`;


export const addAssetSurveyDialog = `
mutation addAssetSurveyDialog($asset_id: uuid = "", $_set: zeus_asset_set_input = {}, $object: zeus_asset_survey_insert_input = {}, $activities: [zeus_activity_insert_input!] = {}) {
  insert_zeus_asset_survey_one(object: $object) {
    __typename
    id
    summary
    severity
    recommendations
    theDate
    createdOn
    status
  }
  update_zeus_asset_by_pk(pk_columns: {id: $asset_id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    status
  }
  insert_zeus_activity(objects: $activities) {
    returning {
      __typename
      id
      name
      description
      createdOn
    }
  }
}
`;


export const addClient = `
mutation addClient($user_id: uuid = "", $id: uuid = "", $object: zeus_client_insert_input = {}, $activity: zeus_activity_insert_input = {} ) {
  insert_zeus_client_one(object: $object) {
    __typename
    id
    name
    description
    createdOn
    status
  }  
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }  
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {client_id: $id, description: "created new client" createdOn: "now()"}}) {
    __typename
    id
    alias
  }
}
`;


export const addContact = `
mutation addContact($user_id: uuid = "", $id: uuid = "", $createdOn: timestamp = "", $address: jsonb = "", $firstName: String = "", $lastName: String = "", $email: String = "", $phone: String = "", $description: String = "") {
  insert_zeus_contact(objects: { id: $id, createdOn: $createdOn, address: $address, firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, description: $description}) {
    returning {
      __typename
      id
      firstName
      lastName
      email
      phone
      description
      createdOn
      status
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {contact_id: $id, email: $email, description: "created new contact" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const addContactForClient = `
mutation addContactForClient($id: uuid = "", $client_id: uuid = "", $createdOn: timestamptz = "", $firstName: String = "", $lastName: String = "", $email: String = "", $phone: String = "", $description: String = "", $status: String = "") {
  insert_zeus_contact(objects: {client_id: $client_id, id: $id, createdOn: $createdOn, firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, description: $description, status: $status}) {
    returning {
      __typename
      id
      firstName
      lastName
      email
      phone
      description
      createdOn
      status
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {contact_id: $id, email: $email, description: "created new contact for client" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const addSupplier = `
mutation addSupplier($user_id: uuid = "", $name: String = "", $description: String = "") {
  insert_zeus_supplier(objects: {name: $name, description: $description}) {
    returning {
      __typename
      id
      name
      description
      createdOn
      status
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {supplier_id: $id, name: $name, description: "created new supplier" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
  }
}
`;


export const addAnnotation = `
mutation addAnnotation($user_id: uuid = "", $id: uuid="", $object: zeus_annotation_insert_input = {}) {
  insert_zeus_annotation_one(object: $object) {
    __typename
    id
    name
    description
    createdOn
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {annotation_id: $id, description: "created annotation" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const addSensorTrigger = `
mutation addSensorTrigger($object: zeus_sensor_to_sensor_trigger_insert_input = {}) {
  insert_zeus_sensor_to_sensor_trigger_one(object: $object) {
    id
    name
    createdOn
    channel
    rms
    status
  }
}
`;


export const deleteTrigger = `
  mutation deleteTrigger($id: uuid = "") {
    delete_zeus_sensor_to_sensor_trigger_by_pk(id: $id) {
      id
      name
      createdOn
    }
  }
`;



export const addNotification = `
mutation addNotification($object: zeus_notification_insert_input = {}) {
  insert_zeus_notification_one(object: $object) {
    __typename
    id
    name
    role
    description
    createdOn
  }
}
`;



export const addFLRA = `
mutation addFLRA($user_id: uuid = "", $id: uuid="", $name: String="", $user_id: uuid="", $client_id: uuid="", $job_id: uuid="", $task_id: uuid="", $pre_job_assessment: jsonb="", $task_steps: jsonb="", $potential_hazards: jsonb="", $hazards: jsonb="", $signature: jsonb="", $hazard_control: jsonb="", $job_completion: jsonb="", $address: jsonb = "", $status: jsonb="", $createdOn: timestamp="") {
  insert_zeus_annotation(objects: {
    id: $id,
    name: $name, 
    address: $address,
    createdOn: $createdOn,
    user_id: $user_id,
    client_id: $client_id,
    job_id: $job_id,
    task_id: $task_id,
    pre_job_assessment: $pre_job_assessment,
    task_steps: $task_steps,
    potential_hazards: $potential_hazards,
    hazards: $hazards,
    signature: $signature,
    hazard_control: $hazard_control,
    job_completion: $job_completion,
    status:  $status
  }) {
    returning {
      __typename
      id
      name
      status
      createdOn
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {flra_id: $id, name: $name, description: "created new FLRA" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;

export const addProductLine = `
mutation addProductLine($user_id: uuid = "", $name: String = "", $description: String = "") {
  insert_zeus_product_line(objects: {name: $name, description: $description}) {
    returning {
      __typename
      id
      name
      description
      createdOn
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {productline_id: $id, name: $name, description: "created new product line" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const pinClientToUser = `
mutation pinClientToUser($object: zeus_user_to_client_insert_input = {}, $activity: zeus_activity_insert_input = {}) {
  insert_zeus_user_to_client_one(object: $object) {
    id
    client_id
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }    
}
`;


export const unpinClientToUser = `
mutation unpinClientToUser($id: uuid = "", $activity: zeus_activity_insert_input = {}) {
  delete_zeus_user_to_client_by_pk(id: $id) {
    id
    user_id
    client_id
    createdOn
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }  
}
`;


export const addRoute = `
mutation addRoute($user_id: uuid = "", $name: String = "", $description: String = "") {
  insert_zeus_route(objects: {name: $name, description: $description}) {
    returning {
      __typename
      id
      name
      description
      createdOn
      status
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {route_id: $id, name: $name, description: "created new route" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const addToolNode = `
mutation addToolNode($user_id: uuid = "", $id: uuid = "", $createdOn: timestamp = "", 
                      $address: jsonb = "", $location: String = "", $name: String = "", 
                      $description: String = "", $node_type: String = "", $lat: numeric = "", 
                      $lng: numeric = "") {
  insert_zeus_tool_node(objects: {id: $id, createdOn: $createdOn, address: $address, location: $location, name: $name, description: $description}) {
    returning {
      __typename
      id
      name
      description
      createdOn
      status
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {tool_node_id: $id, name: $name, description: "created new tool node" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const addToolToNode = `
mutation addToolToNode($user_id: uuid = "", $node_id: uuid = "", $tool_id: uuid = "") {
  insert_zeus_node_to_tool(objects: {node_id: $node_id, tool_id: $tool_id}) {
    returning {
      __typename
      id
      createdOn
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {tool_id: $tool_id, node_id: $node_id, description: "added tool to node" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    createdOn
    status
  }  
}
`;


export const addToolNodeToParent = `
mutation addToolNodeToParent($user_id: uuid = "", $id: uuid = "", $parent_id: uuid = "", $createdOn: timestamp = "", $address: jsonb = "", $location: String = "", $name: String = "", $description: String = "") {
  insert_zeus_tool_node(objects: {id: $id, parent_id: $parent_id, createdOn: $createdOn, address: $address, location: $location, name: $name, description: $description}) {
    returning {
      __typename
      id
      name
      description
      createdOn
      status
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {tool_node_id: $id, parent_node_id: $parent_id, name: $name, description: "added tool node to parent node" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const addTool = `
mutation addTool($user_id: uuid = "", $id: uuid = "", $object: zeus_tool_insert_input = {}) {
  insert_zeus_tool_one(object: $object) {
    __typename
    id
    name
    description
    createdOn
    status
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {tool_id: $id, description: "created new tool" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const updateUsage = `
mutation updateUsage($id: uuid = "", $_set: zeus_tool_user_status_set_input = {}) {
  update_zeus_tool_user_status_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    createdOn
    description
    status
    user {
      __typename
      id
      alias
      status
    }
    tool {
      __typename
      id
      name
      createdOn
      description
      lastCheckedIn
      lastCheckedOut
      status
    }
  }
}
`;


export const updateTool = `
mutation updateTool($user_id: uuid = "", $id: uuid = "", $_set: zeus_tool_set_input = {}) {
  update_zeus_tool_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    status
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {tool_id: $id, description: "updated tool" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const updateToolKit = `
mutation updateToolKit($user_id: uuid = "", $id: uuid = "", $createdOn: timestampz = "", $_set: zeus_tool_node_set_input = {}) {
  update_zeus_tool_node_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    status
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {tool_id: $id, description: "updated toolkit" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const updateToolNode = `
mutation updateToolNode($user_id: uuid = "", $id: uuid = "", $createdOn: timestampz = "", $_set: zeus_tool_node_set_input = {}) {
  update_zeus_tool_node_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    status
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {tool_id: $id, description: "updated tool" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const addToolBooking = `
mutation addToolBooking($objects: [zeus_tool_user_booking_insert_input!] = {}, $activity: zeus_activity_insert_input = {}) {
  insert_zeus_tool_user_booking(objects: $objects) {
    returning {
      __typename
      id
      theDate
      description
      createdOn
      status
    }
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }    
}
`;

// $user_id: uuid = "", $id: uuid = "", $node_id: uuid = "", $theDate: timestamptz = "", $createdOn: timestamptz = "",  $job_id: uuid = "", $description: String = "", $status: String = ""
export const addToolNodeBooking = `
mutation addToolNodeBooking($objects: [zeus_tool_user_booking_insert_input!] = {}, $activity: zeus_activity_insert_input = {}) {
  insert_zeus_tool_user_booking(objects: $objects) {
    returning {
      __typename
      id
      theDate
      description
      createdOn
      status
    }
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }     
}
`;

export const addTicket = `
mutation addTicket($object: zeus_ticket_insert_input = {}, $activity: zeus_activity_insert_input = {}) {
  insert_zeus_ticket_one(object: $object) {
    id
    name
    description
    createdOn
    status
    user {
      id
      alias
    }
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }  
}
`;


export const addImage = `
mutation addImage($user_id: uuid = "", $id: uuid = "", $createdOn: timestampz = "", $object: zeus_image_insert_input = {}) {
  insert_zeus_image_one(object: $object) {
    __typename
    id
    name
    img
    src
    parent_id
    user_id
    createdOn
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {image_id: $id, description: "added image" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const addImages = `
mutation addImages($user_id: uuid = "", $id: uuid = "", $createdOn: timestampz = "", $objects: [zeus_image_insert_input!] = {}, $activities: [zeus_activity_insert_input!] = {}) {
  insert_zeus_image(objects: $objects) {
    returning{
      __typename
      id
      name
      img
      src
      parent_id
      user_id
      createdOn
    }
  }
  insert_zeus_activity(objects: $activities) {
    returning {
      __typename
      id
      name
      description
      createdOn
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {image_id: $id, description: "added images" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;

/*
  update_zeus_tool_user_location(where: {parent_id: {_eq: $_eq}}, _set: $_set) {
    returning {
      id
      lat
      lng
      parent_id
      createdOn
    }
  }
*/


export const updateLocation = `
mutation updateLocation($object: zeus_tool_user_location_insert_input = {}) {
  insert_zeus_tool_user_location_one(object: $object) {
    __typename
    id
    lat
    lng
    parent_id
    user_id
    createdOn
  }
}
`;


export const updateLocation_old = `
mutation updateLocation($user_id: uuid = "", $id: uuid = "", $createdOn: timestampz = "", $_set: zeus_tool_node_set_input = {}) {
  update_zeus_tool_user_location(where: {parent_id: {_eq: $_eq}}, _set: $_set) {
    returning {
      __typename
      id
      lat
      lng
      parent_id
      createdOn
    }
  }
  update_zeus_tool_node_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    status
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {tool_id: $id, description: "updated tool" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;

export const addToolToParent = `
mutation addToolToParent($user_id: uuid = "", $id: uuid = "", $parent_id: uuid = "", $createdOn: timestamp = "", $address: jsonb = "", $location: String = "", $name: String = "", $description: String = "") {
  insert_zeus_tool(objects: {id: $id, parent_id: $parent_id, createdOn: $createdOn, address: $address, location: $location, name: $name, description: $description}) {
    returning {
      __typename
      id
      name
      description
      createdOn
      status
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {tool_id: $id, parent_id: $parent_id, name: $name, description: "added tool to parent node" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const addZeusUser = `
mutation addZeusUser($object: zeus_zeus_user_insert_input! = {}, $activity: zeus_activity_insert_input! = {}) {
  insert_zeus_zeus_user_one(object: $object) {
    __typename
    id
    firstName
    lastName
    alias
    email
    phone
    description
    createdOn
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }
}
`;


export const addZeusUserRoles = `
mutation addZeusUserRoles($objects: [zeus_user_to_role_insert_input!] = {}) {
  insert_zeus_user_to_role(objects: $objects) {
    affected_rows
  }
}
`;


export const checkOutTool_old = `
mutation checkOutTool($id: uuid = "", $status: String = "checked-out", $tool_id: uuid = "", $user_id: uuid = "", $job_id: uuid = "", $createdOn: timestamp = "", $description: String = "") {
  insert_zeus_tool_node(objects: {id: $id, status: $status, tool_id: $tool_id, user_id: $user_id, job_id: $job_id, createdOn: $createdOn, address: $address, location: $location, name: $name, description: $description}) {
    returning {
      __typename
      id
      name
      description
      createdOn
      status
    }
  }
}
`;


export const checkOutTool = `
mutation checkOutTool($user_id: uuid = "", $id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $tool_id: uuid = "", $lat: numeric = "", $lng: numeric = "") {
  insert_zeus_tool_user_status(objects: {id: $id, createdOn: $createdOn, status: "check-out", user_id: $user_id, description: $description, tool_id: $tool_id, lat: $lat, lng: $lng}) {
    returning {
      __typename
      id
      createdOn
      status
      user {
        __typename
        id
        firstName
        lastName
        alias
      }
      tool {
        __typename
        id
        name
      }
    }
  }
  update_zeus_tool_by_pk(pk_columns: {id: $tool_id}, _set: {lastCheckedOut: $createdOn, status: "in-use"}, _append: {history: {id: $id, status: "checked-in", tool_id: $tool_id, user_id: $user_id, job_id: $job_id, createdOn: $createdOn}}) {
    __typename
    id
    name
    status
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {tool_id: $id, description: "checked-out tool" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const checkOutToolNode = `
mutation checkOutToolNode($user_id: uuid = "", $id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $node_id: uuid = "", $lat: numeric = "", $lng: numeric = "") {
  insert_zeus_tool_user_status(objects: {id: $id, createdOn: $createdOn, status: "check-out", user_id: $user_id, description: $description, node_id: $node_id, lat: $lat, lng: $lng}) {
    returning {
      __typename
      id
      createdOn
      status
      user {
        __typename
        id
        firstName
        lastName
        alias
      }
      tool {
        __typename
        id
        name
      }
    }
  }
  update_zeus_tool_node_by_pk(pk_columns: {id: $node_id}, _set: {lastCheckedOut: $createdOn, status: "in-use"}, _append: {history: {id: $id, status: "checked-in", node_id: $node_id, user_id: $user_id, createdOn: $createdOn}}) {
    __typename
    id
    name
    status
  }
  update_zeus_tool(where: {parents: {node_id: {_eq: $node_id}}}, _set: {status: "in-use", lastCheckedOut: $createdOn}) {
    returning {
      __typename
      id
      name
      status
      lastCheckedOut
      lastCheckedIn
      createdOn
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {node_id: $id, description: "checked-out tool node" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const checkInTool_old = `
mutation checkInTool($id: uuid = "", $status: String = "checked-in", $tool_id: uuid = "", $user_id: uuid = "", $job_id: uuid = "", $createdOn: timestamp = "", $description: String = "") {
  insert_zeus_tool_node(objects: {id: $id, status: $status, tool_id: $tool_id, user_id: $user_id, job_id: $job_id, createdOn: $createdOn, address: $address, location: $location, name: $name, description: $description}) {
    returning {
      __typename
      id
      name
      description
      createdOn
      status
    }
  }
}
`;


export const checkInTool = `
mutation checkInTool($user_id: uuid = "", $id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $tool_id: uuid = "", $lat: numeric = "", $lng: numeric = "") {
  insert_zeus_tool_user_status(objects: {id: $id, createdOn: $createdOn, status: "check-in", user_id: $user_id, description: $description, tool_id: $tool_id, lat: $lat, lng: $lng}) {
    returning {
      __typename
      id
      createdOn
      status
      user {
        __typename
        id
        firstName
        lastName
        alias
      }
      tool {
        __typename
        id
        name
      }
    }
  }
  insert_zeus_image(objects: images)
  update_zeus_tool_by_pk(pk_columns: {id: $tool_id}, _set: {lastCheckedIn: $createdOn, status: "ready"}, _append: {history: {id: $id, status: "checked-in", tool_id: $tool_id, user_id: $user_id, createdOn: $createdOn}}) {
    __typename
    id
    name
    status
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {tool_id: $id, description: "checked-in tool" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const checkInToolNode = `
mutation checkInToolNode($user_id: uuid = "", $id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $node_id: uuid = "", $lat: numeric = "", $lng: numeric = "") {
  insert_zeus_tool_user_status(objects: [{id: $id, createdOn: $createdOn, status: "check-in", user_id: $user_id, description: $description, node_id: $node_id, lat: $lat, lng: $lng}]) {
    returning {
      __typename
      id
      createdOn
      status
      user {
        __typename
        id
        firstName
        lastName
        alias
      }
      node {
        __typename
        id
        name
      }
    }
  }
  update_zeus_tool_node_by_pk(pk_columns: {id: $node_id}, _set: {lastCheckedIn: $createdOn, status: "ready"}, _append: {history: {id: $id, status: "checked-in", node_id: $node_id, user_id: $user_id, createdOn: $createdOn}}) {
    __typename
    id
    name
    status
  }
  update_zeus_tool(where: {parents: {node_id: {_eq: $node_id}}}, _set: {status: "ready", lastCheckedIn: $createdOn}) {
    returning {
      __typename
      id
      name
      status
      lastCheckedOut
      lastCheckedIn
      createdOn
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {node_id: $id, description: "checked-in tool node" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const nfcCheckInToolNode = `
mutation nfcCheckInToolNode($user_id: uuid = "", $id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $node_id: uuid = "", $lat: numeric = "", $lng: numeric = "", $images: [zeus_image_insert_input!] = {}) {
  insert_zeus_tool_user_status(objects: [{id: $id, createdOn: $createdOn, status: "check-in", user_id: $user_id, description: $description, node_id: $node_id, lat: $lat, lng: $lng}]) {
    returning {
      __typename
      id
      createdOn
      status
      user {
        __typename
        id
        firstName
        lastName
        alias
      }
      node {
        __typename
        id
        name
      }
    }
  }
  insert_zeus_image(objects: $images){
    returning {
      __typename
      id
      name
      parent_id
    }    
  }
  update_zeus_tool_node_by_pk(pk_columns: {id: $node_id}, _set: {lastCheckedIn: $createdOn, status: "ready"}, _append: {history: {id: $id, status: "checked-in", node_id: $node_id, user_id: $user_id, createdOn: $createdOn}}) {
    __typename
    id
    name
    status
  }
  update_zeus_tool(where: {parents: {node_id: {_eq: $node_id}}}, _set: {status: "ready", lastCheckedIn: $createdOn}) {
    returning {
      __typename
      id
      name
      status
      lastCheckedOut
      lastCheckedIn
      createdOn
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {node_id: $id, description: "checked-in tool node" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const nfcCheckInTool = `
mutation nfcCheckInTool($user_id: uuid = "", $id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $tool_id: uuid = "", $lat: numeric = "", $lng: numeric = "", $images: [zeus_image_insert_input!] = {}) {
  insert_zeus_tool_user_status(objects: [{id: $id, createdOn: $createdOn, status: "check-in", user_id: $user_id, description: $description, tool_id: $tool_id, lat: $lat, lng: $lng}]) {
    returning {
      __typename
      id
      createdOn
      status
      user {
        __typename
        id
        firstName
        lastName
        alias
      }
      node {
        __typename
        id
        name
      }
    }
  }
  insert_zeus_image(objects: $images){
    returning {
      __typename
      id
      name
      parent_id
    }    
  }
  update_zeus_tool_by_pk(pk_columns: {id: $tool_id}, _set: {lastCheckedIn: $createdOn, status: "ready"}, _append: {history: {id: $id, status: "checked-in", tool_id: $tool_id, user_id: $user_id, createdOn: $createdOn}}) {
    __typename
    id
    name
    status
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {tool_id: $id, description: "checked-in tool" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;

/*
  // tool_status
  status = "check-out"
  status = "check-in"
  // tool
  status = "retired"
  status = "out-of-service"
  status = "out-for-calibration"
  status = "ready"
  status = "in-use"
*/

//////////
/*
  setToolNodeOutOfService, 
  setToolNodeOutForCalibration, 
  setToolNodeRetired, 
  setToolNodeReady
*/

export const setToolNodeOutOfService = `
mutation setToolNodeOutOfService($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $node_id: uuid = "", $lat: numeric = "", $lng: numeric = "") {
  insert_zeus_tool_user_status(objects: {id: $id, job_id: $job_id, createdOn: $createdOn, status: "check-out", user_id: $user_id, description: $description, node_id: $node_id, lat: $lat, lng: $lng}) {
    returning {
      __typename
      id
      createdOn
      status
      user {
        __typename
        id
        firstName
        lastName
        alias
      }
      job {
        __typename
        id
        name
        createdOn
        customerPO
        description
        status
      }
      tool {
        __typename
        id
        name
      }
    }
  }
  update_zeus_tool_node_by_pk(pk_columns: {id: $node_id}, _set: {lastCheckedOut: $createdOn, status: "out-of-service"}, _append: {history: {id: $id, status: "out-of-service", node_id: $node_id, user_id: $user_id, job_id: $job_id, createdOn: $createdOn}}) {
    __typename
    id
    name
    status
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {node_id: $id, description: "checked tool out-of-service" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const setToolOutOfService = `
mutation setToolOutOfService($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $tool_id: uuid = "", $lat: numeric = "", $lng: numeric = "") {
  insert_zeus_tool_user_status(objects: {id: $id, job_id: $job_id, createdOn: $createdOn, status: "check-out", user_id: $user_id, description: $description, tool_id: $tool_id, lat: $lat, lng: $lng}) {
    returning {
      __typename
      id
      createdOn
      status
      user {
        __typename
        id
        firstName
        lastName
        alias
      }
      job {
        __typename
        id
        name
        createdOn
        customerPO
        description
        status
      }
      tool {
        __typename
        id
        name
      }
    }
  }
  update_zeus_tool_by_pk(pk_columns: {id: $tool_id}, _set: {lastCheckedOut: $createdOn, status: "out-of-service"}, _append: {history: {id: $id, status: "out-of-service", tool_id: $tool_id, user_id: $user_id, job_id: $job_id, createdOn: $createdOn}}) {
    __typename
    id
    name
    status
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {tool_id: $id, description: "checked tool out-of-service" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const setToolNodeOutForCalibration = `
mutation setToolNodeOutForCalibration($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $node_id: uuid = "", $lat: numeric = "", $lng: numeric = "") {
  insert_zeus_tool_user_status(objects: {id: $id, job_id: $job_id, createdOn: $createdOn, status: "check-out", user_id: $user_id, description: $description, node_id: $node_id, lat: $lat, lng: $lng}) {
    returning {
      __typename
      id
      createdOn
      status
      user {
        __typename
        id
        firstName
        lastName
        alias
      }
      job {
        __typename
        id
        name
        createdOn
        customerPO
        description
        status
      }
      tool {
        __typename
        id
        name
      }
    }
  }
  update_zeus_tool_node_by_pk(pk_columns: {id: $node_id}, _set: {lastCheckedOut: $createdOn, status: "out-for-calibration"}, _append: {history: {id: $id, status: "out-for-calibration", node_id: $node_id, user_id: $user_id, job_id: $job_id, createdOn: $createdOn}}) {
    __typename
    id
    name
    status
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {node_id: $id, description: "checked tool out-for-calibration" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const setToolOutForCalibration = `
mutation setToolOutForCalibration($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $tool_id: uuid = "", $lat: numeric = "", $lng: numeric = "") {
  insert_zeus_tool_user_status(objects: {id: $id, job_id: $job_id, createdOn: $createdOn, status: "check-out", user_id: $user_id, description: $description, tool_id: $tool_id, lat: $lat, lng: $lng}) {
    returning {
      __typename
      id
      createdOn
      status
      user {
        __typename
        id
        firstName
        lastName
        alias
      }
      job {
        __typename
        id
        name
        createdOn
        customerPO
        description
        status
      }
      tool {
        __typename
        id
        name
      }
    }
  }
  update_zeus_tool_by_pk(pk_columns: {id: $tool_id}, _set: {lastCheckedOut: $createdOn, status: "out-for-calibration"}, _append: {history: {id: $id, status: "out-for-calibration", tool_id: $tool_id, user_id: $user_id, job_id: $job_id, createdOn: $createdOn}}) {
    __typename
    id
    name
    status
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {tool_id: $id, description: "checked tool out-for-calibration" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const setToolNodeRetired = `
mutation setToolNodeRetired($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $node_id: uuid = "", $lat: numeric = "", $lng: numeric = "") {
  insert_zeus_tool_user_status(objects: {id: $id, job_id: $job_id, createdOn: $createdOn, status: "check-out", user_id: $user_id, description: $description, node_id: $node_id, lat: $lat, lng: $lng}) {
    returning {
      __typename
      id
      createdOn
      status
      user {
        __typename
        id
        firstName
        lastName
        alias
      }
      job {
        __typename
        id
        name
        createdOn
        customerPO
        description
        status
      }
      tool {
        __typename
        id
        name
      }
    }
  }
  update_zeus_tool_node_by_pk(pk_columns: {id: $node_id}, _set: {lastCheckedOut: $createdOn, status: "retired"}, _append: {history: {id: $id, status: "checked-out retired", node_id: $node_id, user_id: $user_id, job_id: $job_id, createdOn: $createdOn}}) {
    __typename
    id
    name
    status
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {node_id: $id, description: "checked tool retired" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const setToolRetired = `
mutation setToolRetired($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $tool_id: uuid = "", $lat: numeric = "", $lng: numeric = "") {
  insert_zeus_tool_user_status(objects: {id: $id, job_id: $job_id, createdOn: $createdOn, status: "check-out", user_id: $user_id, description: $description, tool_id: $tool_id, lat: $lat, lng: $lng}) {
    returning {
      __typename
      id
      createdOn
      status
      user {
        __typename
        id
        firstName
        lastName
        alias
      }
      job {
        __typename
        id
        name
        createdOn
        customerPO
        description
        status
      }
      tool {
        __typename
        id
        name
      }
    }
  }
  update_zeus_tool_by_pk(pk_columns: {id: $tool_id}, _set: {lastCheckedOut: $createdOn, status: "retired"}, _append: {history: {id: $id, status: "checked-out retired", tool_id: $tool_id, user_id: $user_id, job_id: $job_id, createdOn: $createdOn}}) {
    __typename
    id
    name
    status
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {tool_id: $id, description: "checked tool retired" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const setToolNodeReady = `
mutation setToolNodeReady($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $node_id: uuid = "", $lat: numeric = "", $lng: numeric = "") {
  insert_zeus_tool_user_status(objects: {id: $id, job_id: $job_id, createdOn: $createdOn, status: "check-in", user_id: $user_id, description: $description, node_id: $node_id, lat: $lat, lng: $lng}) {
    returning {
      __typename
      id
      createdOn
      status
      user {
        __typename
        id
        firstName
        lastName
        alias
      }
      job {
        __typename
        id
        name
        createdOn
        customerPO
        description
        status
      }
      tool {
        __typename
        id
        name
      }
    }
  }
  update_zeus_tool_node_by_pk(pk_columns: {id: $node_id}, _set: {lastCheckedOut: $createdOn, status: "ready"}, _append: {history: {id: $id, status: "checked-in ready", node_id: $node_id, user_id: $user_id, job_id: $job_id, createdOn: $createdOn}}) {
    __typename
    id
    name
    status
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {node_id: $id, description: "checked-in tool ready" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const setToolReady = `
mutation setToolReady($user_id: uuid = "", $id: uuid = "", $job_id: uuid = "", $createdOn: timestamptz = "", $description: String = "", $tool_id: uuid = "", $lat: numeric = "", $lng: numeric = "") {
  insert_zeus_tool_user_status(objects: {id: $id, job_id: $job_id, createdOn: $createdOn, status: "check-in", user_id: $user_id, description: $description, tool_id: $tool_id, lat: $lat, lng: $lng}) {
    returning {
      __typename
      id
      createdOn
      status
      user {
        __typename
        id
        firstName
        lastName
        alias
      }
      job {
        __typename
        id
        name
        createdOn
        customerPO
        description
        status
      }
      tool {
        __typename
        id
        name
      }
    }
  }
  update_zeus_tool_by_pk(pk_columns: {id: $tool_id}, _set: {lastCheckedOut: $createdOn, status: "ready"}, _append: {history: {id: $id, status: "checked-in ready", tool_id: $tool_id, user_id: $user_id, job_id: $job_id, createdOn: $createdOn}}) {
    __typename
    id
    name
    status
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {tool_id: $id, description: "checked-in tool ready" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;

///////////

export const updateClient = `
mutation updateClient($user_id: uuid = "", $id: uuid = "", $_set: zeus_client_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_client_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {client_id: $id, description: "updated client" createdOn: "now()}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;



export const updateSupplier = `
mutation updateSupplier($user_id: uuid = "", $id: uuid = "", $_set: zeus_supplier_set_input = {}) {
  update_zeus_supplier_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    status
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {supplier_id: $id, description: "updated supplier" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const updateQuotation = `
mutation updateQuotation($user_id: uuid = "", $id: uuid = "", $_set: zeus_sales_document_set_input = {}) {
  update_zeus_sales_document_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    status
  }
  insert_zeus_activity_one(object: {user_id: $user_id, parent_id: $id, name: "quotation updated", description: "updated quotation details"}) {
    __typename
    id
    name
    description
    createdOn
  }   
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {document_id: $id, description: "updated quotation" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const updateContact = `
mutation updateContact($user_id: uuid = "", $id: uuid = "", $_set: zeus_contact_set_input = {}) {
  update_zeus_contact_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    firstName
    lastName
    email
    description
    createdOn
    status
  }
  insert_zeus_activity_one(object: {user_id: $user_id, parent_id: $id, name: "contact updated", description: "updated contact details"}) {
    __typename
    id
    name
    description
    createdOn
  }   
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {contact_id: $id, description: "updated contact" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const updateUser = `
mutation updateUser($id: uuid = "", $_set: zeus_zeus_user_set_input = {}) {
  update_zeus_zeus_user_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    firstName
    lastName
    email
    alias
    description
    createdOn
    status
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {contact_id: $id, description: "updated contact" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;

export const addSensor = `
mutation addSensor($object: zeus_sensor_insert_input = {}, $activities: [zeus_activity_insert_input!] = {}) {
  insert_zeus_sensor_one(object: $object) {
    __typename
    id
    name
    serialNumber
    alias
    manufacturer
    status
    description
    createdOn
    status
  }
  insert_zeus_activity(objects: $activities) {
    affected_rows
  }
}
`;


export const addComponentType = `
mutation addComponentType($object: zeus_component_type_insert_input = {}, $activities: [zeus_activity_insert_input!] = {}) {
  insert_zeus_component_type_one(object: $object) {
    __typename
    id
    name
    description
    createdOn
  }
  insert_zeus_activity(objects: $activities) {
    affected_rows
  }
}
`;

export const addConditionDescriptorType = `
mutation addConditionDescriptorType($object: zeus_condition_descriptor_type_insert_input = {}, $activities: [zeus_activity_insert_input!] = {}) {
  insert_zeus_condition_descriptor_type_one(object: $object) {
    __typename
    id
    name
    description
    createdOn
  }
  insert_zeus_activity(objects: $activities) {
    affected_rows
  }
}
`;

export const addConditionType = `
mutation addConditionType($object: zeus_condition_type_insert_input = {}, $activities: [zeus_activity_insert_input!] = {}) {
  insert_zeus_condition_type_one(object: $object) {
    __typename
    id
    name
    description
    createdOn
  }
  insert_zeus_activity(objects: $activities) {
    affected_rows
  }
}
`;

export const updateComponentType = `
mutation updateComponentType($id: uuid = "", $_set: zeus_component_type_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_component_type_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }    
}
`;

export const updateConditionDescriptorType = `
mutation updateConditionDescriptorType($id: uuid = "", $_set: zeus_condition_descriptor_type_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_condition_descriptor_type_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }    
}
`;


export const updateConditionType = `
mutation updateConditionType($id: uuid = "", $_set: zeus_condition_type_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_condition_type_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }    
}
`;


export const updateAsset = `
mutation updateAsset($user_id: uuid = "", $id: uuid = "", $_set: zeus_asset_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_asset_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }    
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {asset_id: $id, description: "updated asset" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;

//updateAssetField
export const updateAssetField = `
mutation updateAssetField($id: uuid = "", $_set: zeus_asset_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_asset_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
}
`;


export const updateAssetStatus = `
mutation updateAssetStatus($user_id: uuid = "", $id: uuid = "", $_set: zeus_asset_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_asset_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
}
`;


export const updateRoute = `
mutation updateRoute($user_id: uuid = "", $id: uuid = "", $_set: zeus_route_set_input = {}) {
  update_zeus_route_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    status
  }
  insert_zeus_activity_one(object: {user_id: $user_id, parent_id: $id, name: "route updated", description: "updated route details"}) {
    __typename
    id
    name
    description
    createdOn
  }   
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {route_id: $id, description: "updated route" createdOn: $createdOn}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const updateJobBoardEntry = `
mutation updateJobBoardEntry($user_id: uuid = "", $id: uuid = "", $_set: zeus_jobboard_entry_set_input = {}) {
  update_zeus_jobboard_entry_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    primacQuoteName
    createdOn
    status
    salesDocuments {
      __typename
      id
      name
      amount
      createdOn
      document_type
    }
  }
  insert_zeus_activity_one(object: {user_id: $user_id, parent_id: $id, name: "job updated", description: "updated job board entry"}) {
    __typename
    id
    name
    description
    createdOn
  }   
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {job_id: $id, description: "updated job board entry" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const updateJobBoardEntryStatus = `
mutation updateJobBoardEntryStatus($user_id: uuid = "", $id: uuid = "", $_set: zeus_jobboard_entry_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_jobboard_entry_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    primacQuoteName
    createdOn
    status
    salesDocuments {
      __typename
      id
      name
      amount
      createdOn
      document_type
    }
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {job_id: $id, description: "updated job board entry" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const updateJobBoardEntryField = `
mutation updateJobBoardEntryField($user_id: uuid = "", $id: uuid = "", $_set: zeus_jobboard_entry_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_jobboard_entry_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    primacQuoteName
    createdOn
    status
    salesDocuments {
      __typename
      id
      name
      amount
      createdOn
      document_type
    }
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {job_id: $id, description: "updated job board entry" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;

//updateJobAddQuoteName

export const updateGatewayField = `
mutation updateGatewayField($id: uuid = "", $_set: zeus_gateway_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_gateway_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    alias
    description
    createdOn
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
}
`;


export const updateAnnotationField = `
mutation updateAnnotationField($user_id: uuid = "", $id: uuid = "", $_set: zeus_annotation_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_annotation_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
}
`;


export const updateAssetSurveyField = `
mutation updateAssetSurveyField($user_id: uuid = "", $id: uuid = "", $_set: zeus_asset_survey_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_asset_survey_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    severity
    recommendations
    notification_num
    modifiedOn
    summary
    status
    theDate
    createdOn
    findings_from_work_completed
    asset {
      __typename
      id
      name
      description
      currentCondition
      createdOn
      status
    }
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
}
`;


export const updateToolField = `
mutation updateToolField($id: uuid = "", $_set: zeus_tool_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_tool_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    modifiedOn
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
}
`;


export const updateTrigger = `
mutation updateTrigger($id: uuid = "", $_set: zeus_sensor_to_sensor_trigger_set_input = {}, $activities: [zeus_activity_insert_input!] = {}) {
  update_zeus_sensor_to_sensor_trigger_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    status
  }
  insert_zeus_activity(objects: $activities) {
    returning {
      __typename
      id
      name
      description
      createdOn
    }
  }  
}
`;


export const updateSensorTrigger = `

`;


export const updateTicketField = `
mutation updateTicketField($id: uuid = "", $_set: zeus_ticket_set_input = {}, $activities: [zeus_activity_insert_input!] = {}) {
  update_zeus_ticket_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    modifiedOn
    status
  }
  insert_zeus_activity(objects: $activities) {
    returning {
      __typename
      id
      name
      description
      createdOn
    }
  }   
}
`;


export const updateMeasurementField = `
mutation updateMeasurementField($id: uuid = "", $_set: zeus_ticket_set_input = {}, $activities: [zeus_activity_insert_input!] = {}) {
  update_zeus_measurement_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    timestamp
    description
    createdOn
    modifiedOn
    status
  }
  insert_zeus_activity(objects: $activities) {
    returning {
      __typename
      id
      name
      description
      createdOn
    }
  }   
}
`;

// updateToolNodeField
export const updateToolNodeField = `
mutation updateToolNodeField($user_id: uuid = "", $id: uuid = "", $_set: zeus_tool_node_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_tool_node_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    modifiedOn
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
}
`;


export const updateClientField = `
mutation updateClientField($id: uuid = "", $_set: zeus_client_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_client_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    modifiedOn
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
}
`;


export const updateClientNodeField = `
mutation updateClientNodeField($user_id: uuid = "", $id: uuid = "", $_set: zeus_client_node_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_client_node_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    modifiedOn
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
}
`;


export const updatePrimacPO = `
mutation updatePrimacPO {
  update_zeus_document_counter(where: {name: {_eq: "primacPO"}}, _inc: {sequence: 1}) {
    affected_rows
    returning {
      id
      name
      sequence
    }
  }
}
`;


export const updateJobNum = `
mutation updateJobNum {
  update_zeus_document_counter(where: {name: {_eq: "jobNum"}}, _inc: {sequence: 1}) {
    affected_rows
    returning {
      id
      name
      sequence
    }
  }
}
`;


export const updateAnalysisBloC = `
mutation updateAnalysisBloC($id: uuid = "", $_set: zeus_analysis_bloc_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_analysis_bloc_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
    name
    description
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }  
}
`;


//updateJobBoardScheduleSpan
export const updateJobSetPrimacPO = `
mutation updateJobPrimacPO($user_id: uuid = "", $id: uuid = "", $_set: zeus_jobboard_entry_set_input = {}) {
  update_zeus_jobboard_entry_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    primacQuoteName
    createdOn
    status
    salesDocuments {
      __typename
      id
      name
      amount
      createdOn
      document_type
    }
  }
  insert_zeus_activity_one(object: {user_id: $user_id, parent_id: $id, name: "job updated with Primac PO#", description: "updated job board entry with Primac PO#"}) {
    __typename
    id
    name
    description
    createdOn
  }    
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {job_id: $id, description: "updated job board entry" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const updateJobPrimacPO = `
mutation updateJobPrimacPO($user_id: uuid = "", $id: uuid = "", $_set: zeus_jobboard_entry_set_input = {}) {
  update_zeus_jobboard_entry_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    primacQuoteName
    createdOn
    status
    salesDocuments {
      __typename
      id
      name
      amount
      createdOn
      document_type
    }
  }
  insert_zeus_activity_one(object: {user_id: $user_id, parent_id: $id, name: "job updated with Primac PO#", description: "updated job board entry with Primac PO#"}) {
    __typename
    id
    name
    description
    createdOn
  }    
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {job_id: $id, description: "updated job board entry" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;

export const updateJobClosed = `
mutation updateJobClosed($user_id: uuid = "", $id: uuid = "", $activity: zeus_activity_insert_input = {}, $_set: zeus_jobboard_entry_set_input = {}) {
  update_zeus_jobboard_entry_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    primacQuoteName
    createdOn
    status
    salesDocuments {
      __typename
      id
      name
      amount
      createdOn
      document_type
    }
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }    
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {job_id: $id, description: "updated job board entry" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`; 

export const updateJobShipped = `
mutation updateJobShipped($user_id: uuid = "", $id: uuid = "", $activities: [zeus_activity_insert_input!] = {}, $_set: zeus_jobboard_entry_set_input = {}) {
  update_zeus_jobboard_entry_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    primacQuoteName
    createdOn
    status
    salesDocuments {
      __typename
      id
      name
      amount
      createdOn
      document_type
    }
  }
  insert_zeus_activity(objects: $activities) {
    returning{
      __typename
      id
      name
      description
      createdOn
    }
  }    
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {job_id: $id, description: "updated job board entry" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`; 

//updateTicketClosed
export const updateTicketClosed = `
mutation updateTicketClosed($user_id: uuid = "", $id: uuid = "", $activity: zeus_activity_insert_input = {}, $_set: zeus_ticket_set_input = {}) {
  update_zeus_ticket_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }    
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {ticket_id: $id, description: "closed ticket" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`; 

export const updateJobScheduled = `
mutation updateJobScheduled($user_id: uuid = "", $id: uuid = "", $activity: zeus_activity_insert_input = {}, $_set: zeus_jobboard_entry_set_input = {}) {
  update_zeus_jobboard_entry_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    primacQuoteName
    createdOn
    status
    salesDocuments {
      __typename
      id
      name
      amount
      createdOn
      document_type
    }
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }    
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {job_id: $id, description: "updated job board entry" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`; //

export const addJobboardSchedules = `
mutation addJobboardSchedules($user_id: uuid = "", $id: uuid = "", $objects: [zeus_jobboard_schedule_insert_input!] = {}, $_set: zeus_jobboard_entry_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  insert_zeus_jobboard_schedule(objects: $objects) {
    affected_rows
    returning {
      __typename
      id
      theDate
      description
      status
      createdOn
      modifiedOn
      user {
        __typename
        id
        alias
      }
      job {
        __typename
        id
        name
        description
        createdOn
        quoteAmount
        status
        scheduled
        poAmount
        invoiceNum
        invoiceDate
        invoiceAmount
        customerPO
        createdBy
        companyName
        completedOn        
      }
      technician {
        __typename
        id
        alias
      }
    }
  }
  update_zeus_jobboard_entry_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    status
    salesDocuments {
      __typename
      id
      name
      amount
      createdOn
      document_type
    }
  }  
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }    
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {job_id: $id, description: "updated job schedule" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }  
}
`;

export const updateJobBoardSchedule = `
mutation updateJobBoardScehdule($id: uuid = "", $_set: zeus_jobboard_schedule_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_jobboard_schedule_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    theDate
    description
    status
    createdOn
    modifiedOn
    technician {
      __typename
      id
      alias
    }
    user {
      __typename
      id
      alias
    }
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }    
}`;


export const updateJobBoardScheduleSpan = `
mutation updateJobBoardScheduleSpan($span_id: uuid = "", $_set: zeus_jobboard_schedule_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_jobboard_schedule(where: {span_id: {_eq: $span_id}}, _set: $_set) {
    returning {
      __typename
      id
      theDate
      status
      description
      createdOn
      modifiedOn
      job {
        __typename
        id
        name
        description
      }
      technician {
        __typename
        id
        alias
      }
    }
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }     
}`;
//updateJobBoardScheduleSpan

export const updateJobboardDaySchedule = `
mutation updateJobboardDaySchedule($id: uuid = "", $_set: zeus_jobboard_schedule_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_jobboard_schedule_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    theDate
    status
    description
    createdOn
    modifiedOn
    job {
      __typename
      id
      name
      description
    }
    technician {
      __typename
      id
      alias
    }
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }     
}`;

//deleteBooking
export const deleteBooking = `
mutation deleteBooking($id: uuid = "", $activity: zeus_activity_insert_input = {}) {
  delete_zeus_tool_user_booking_by_pk(id: $id) {
    id
    theDate
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }    
}`;


export const deleteUserRole = `
mutation deleteUserRole($user_to_role_id: uuid = "", $user_id: uuid = "", $activity: zeus_activity_insert_input = {}) {
  delete_zeus_user_to_role_by_pk(id: $user_to_role_id) {
    id
    createdOn
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _set: {modifiedOn: "now()"}) {
    __typename
    id
    alias
    modifiedOn
  }  
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }    
}  
`;

export const deleteJobBoardSchedule = `
mutation deleteJobBoardSchedule($id: uuid = "", $activity: zeus_activity_insert_input = {}) {
  delete_zeus_jobboard_schedule_by_pk(id: $id) {
    id
    theDate
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }    
}`;

export const deleteJobBoardScheduleSpan = `
mutation deleteJobBoardScheduleSpan($span_id: uuid = "", $activity: zeus_activity_insert_input = {}) {
  delete_zeus_jobboard_schedule(where: {span_id: {_eq: $span_id}}) {
    affected_rows
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }    
}`;

export const updateJobCustomerPO = `
mutation updateJobCustomerPO($user_id: uuid = "", $id: uuid = "", $_set: zeus_jobboard_entry_set_input = {}) {
  update_zeus_jobboard_entry_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    primacQuoteName
    createdOn
    status
    salesDocuments {
      __typename
      id
      name
      amount
      createdOn
      document_type
    }
  }
  insert_zeus_activity_one(object: {user_id: $user_id, parent_id: $id, name: "job updated with Client PO#", description: "updated job board entry with Client PO#"}) {
    __typename
    id
    name
    description
    createdOn
  }    
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {job_id: $id, description: "updated job board entry" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;

export const addJobInvoice = `
mutation addJobInvoice($object: zeus_sales_document_insert_input = {}, $activity: zeus_activity_insert_input = {} ) {
  insert_zeus_sales_document_one(object: $object) {
    __typename
    id
    name
    description
    status
    theDate
    createdOn
    jobBoardEntry {
      __typename
      id
      name
      description
      companyName
      status
    }
  }  
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }     
}
`;//updateJobInvoiced


export const addJobCustomerPO = `
mutation addJobCustomerPO($job_id: uuid = "", $_set: zeus_jobboard_entry_set_input = {}, $object: zeus_sales_document_insert_input = {}, $activities: [zeus_activity_insert_input!] = {} ) {
  insert_zeus_sales_document_one(object: $object) {
    __typename
    id
    name
    description
    status
    theDate
    createdOn
    jobBoardEntry {
      __typename
      id
      name
      description
      companyName
      status
    }
  }  
  update_zeus_jobboard_entry_by_pk(pk_columns: {id: $job_id}, _set: $_set) {
    __typename
    id
    name
    description
    primacQuoteName
    createdOn
    status
    salesDocuments {
      __typename
      id
      name
      amount
      createdOn
      document_type
    }
  }  
  insert_zeus_activity(objects: $activities) {
    affected_rows
    returning {
      __typename
      id
      name
      description
      createdOn
    }
  }     
}`;



export const addSalesDocument = `
mutation addSalesDocument($object: zeus_sales_document_insert_input = {}, $activity: zeus_activity_insert_input = {} ) {
  insert_zeus_sales_document_one(object: $object) {
    __typename
    id
    name
    description
    status
    theDate
    createdOn
    jobBoardEntry {
      __typename
      id
      name
      description
      companyName
      status
    }
  }  
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }     
}`;




export const updateJobInvoice = `
mutation updateJobInvoice($id: uuid = "", $user_id: uuid = "", $_set: zeus_sales_document_set_input = {}, $activities: [zeus_activity_insert_input!] = {} ) {
  update_zeus_sales_document_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    amount
    description
    status
    theDate
    createdOn
    jobBoardEntry {
      __typename
      id
      name
      description
      companyName
      status
    }
  }  
  insert_zeus_activity(objects: $activities) {
    affected_rows
    returning{
      __typename
      id
      name
      description
      createdOn
    }
  }   
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {job_id: $id, description: "updated job board entry updated invoice" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;//updateJobInvoice


export const updateJobPurchaseOrder = `
mutation updateJobPurchaseOrder($id: uuid = "", $user_id: uuid = "", $_set: zeus_sales_document_set_input = {}, $activities: [zeus_activity_insert_input!] = {} ) {
  update_zeus_sales_document_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    amount
    description
    status
    theDate
    createdOn
    jobBoardEntry {
      __typename
      id
      name
      description
      companyName
      status
    }
  }  
  insert_zeus_activity(objects: $activities) {
    affected_rows
    returning{
      __typename
      id
      name
      description
      createdOn
    }
  }     
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {job_id: $id, description: "updated job board entry updated purchase order" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;//updateJobPurchaseOrder


export const updateAssetSurvey = `
mutation updateAssetSurvey($id: uuid = "", $_set: zeus_asset_survey_set_input = {}, $activities: [zeus_activity_insert_input!] = {} ) {
  update_zeus_asset_survey_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    severity
    recommendations
    notification_num
    modifiedOn
    summary
    status
    theDate
    createdOn
    findings_from_work_completed
    asset {
      __typename
      id
      name
      description
      currentCondition
      createdOn
      status
    }
  }  
  insert_zeus_activity(objects: $activities) {
    returning {
      __typename
      id
      name
      description
      createdOn
    }  
  }  
}
`;


export const updateJobInvoiced_1 = `
mutation updateJobInvoiced($user_id: uuid = "", $id: uuid = "", $_set: zeus_jobboard_entry_set_input = {}) {
  update_zeus_jobboard_entry_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    primacQuoteName
    createdOn
    status
    salesDocuments {
      __typename
      id
      name
      amount
      createdOn
      document_type
    }
  }
  insert_zeus_activity_one(object: {user_id: $user_id, parent_id: $id, name: "job invoiced", description: "updated job board entry with invoice amount and date"}) {
    __typename
    id
    name
    description
    createdOn
  }    
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {job_id: $id, description: "updated job board entry" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;//updateJobInvoiced


export const updateJobAssignTech = `
mutation updateJobAssignTech($user_id: uuid = "", $id: uuid = "", $_set: zeus_jobboard_entry_set_input = {}) {
  update_zeus_jobboard_entry_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    status
    salesDocuments {
      __typename
      id
      name
      amount
      createdOn
      document_type
    }
  }
  insert_zeus_activity_one(object: {user_id: $user_id, parent_id: $id, name: "tech assigned to job", description: "updated job board entry with tech assigned"}) {
    __typename
    id
    name
    description
    createdOn
  }    
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {job_id: $id, description: "updated job board entry" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;//updateJobAssignTech


export const updateGatewayAssignClient = `
mutation updateGatewayAssignClient($user_id: uuid = "", $id: uuid = "", $client_id: uuid = "", $description: String = "") {
  update_zeus_gateway_by_pk(pk_columns: {id: $id}, _set: {client_id: $client_id, status: "active", modifiedOn: "now()"}) {
    __typename
    id
    name
    alias
    client_id
    description
    createdOn
    status
    client {
      __typename
      id
      name
      createdOn
      status
    }
  }
  insert_zeus_activity(objects: [ 
    {user_id: $user_id, parent_id: $id, name: "client assigned to gateway", description: $description},
    {user_id: $user_id, parent_id: $client_id, name: "gateway assigned to client", description: $description}
  ]) {
    affected_rows
    returning {
      id
      name
      description
      createdOn
    }
  }    
}
`;//updateGatewayAssignClient


export const updateSensorAssignClient = `
mutation updateSensorAssignClient($user_id: uuid = "", $id: uuid = "", $client_id: uuid = "", $description: String = "") {
  update_zeus_sensor_by_pk(pk_columns: {id: $id}, _set: {client_id: $client_id, status: "active", modifiedOn: "now()"}) {
    __typename
    id
    name
    alias
    description
    createdOn
    modifiedOn
    serialNumber
    status
    client {
      __typename
      id
      name
      createdOn
      status
    }
  }
  insert_zeus_activity(objects: [ 
    {user_id: $user_id, parent_id: $id, name: "client assigned to sensor", description: $description},
    {user_id: $user_id, parent_id: $client_id, name: "sensor assigned to client", description: $description}
  ]) {
    affected_rows
    returning {
      id
      name
      description
      createdOn
    }
  }    
}
`;


export const updateSensorsForGatewayAssignClient = `
mutation updateSensorsForGatewayAssignClient($gateway_id: uuid = "", $client_id: uuid = "", $activities: [zeus_activity_insert_input!] = {}) {
  update_zeus_sensor(where: {gateway_id: {_eq: $gateway_id}}, _set: {client_id: $client_id, status: "active", modifiedOn: "now()"}) {
    affected_rows
  }
  insert_zeus_activity(objects: $activities) {
    affected_rows
  }    
}
`;


export const updateSensorAssignAsset = `
mutation updateSensorAssignAsset($user_id: uuid = "", $id: uuid = "", $asset_id: uuid = "", $description: String = "") {
  update_zeus_sensor_by_pk(pk_columns: {id: $id}, _set: {asset_id: $asset_id, status: "active", modifiedOn: "now()"}) {
    __typename
    id
    name
    alias
    description
    createdOn
    modifiedOn
    serialNumber
    status
    asset {
      __typename
      id
      name
      createdOn
      status
      client {
        __typename
        id
        name
        createdOn
        status
      }
    }
  }
  insert_zeus_activity(objects: [ 
    {user_id: $user_id, parent_id: $id, name: "asset assigned to sensor", description: $description},
    {user_id: $user_id, parent_id: $asset_id, name: "sensor assigned to asset", description: $description}
  ]) {
    affected_rows
    returning {
      id
      name
      description
      createdOn
    }
  }    
}
`;


export const updateGatewayAssignParent = `
mutation updateGatewayAssignParent($user_id: uuid = "", $id: uuid = "", $parent_id: uuid = "", $description: String = "") {
  update_zeus_gateway_by_pk(pk_columns: {id: $id}, _set: {parent_id: $parent_id, modifiedOn: "now()"}) {
    __typename
    id
    name
    alias
    description
    createdOn
    modifiedOn
    serialNumber
    status
    client {
      __typename
      id
      name
      amount
      createdOn
      status
    }
  }
  insert_zeus_activity_one(object: {user_id: $user_id, parent_id: $id, name: "sensor assigned to parent", description: $description}) {
    __typename
    id
    name
    description
    createdOn
  }    
}
`;


export const updateSensorAssignParent = `
mutation updateSensorAssignParent($user_id: uuid = "", $id: uuid = "", $parent_id: uuid = "", $description: String = "") {
  update_zeus_sensor_by_pk(pk_columns: {id: $id}, _set: {parent_id: $parent_id, modifiedOn: "now()"}) {
    __typename
    id
    name
    alias
    client_id
    parent_id
    description
    createdOn
    status
    client {
      __typename
      id
      name
      amount
      createdOn
      status
    }
  }
  insert_zeus_activity_one(object: {user_id: $user_id, parent_id: $id, name: "sensor assigned to parent", description: $description}) {
    __typename
    id
    name
    description
    createdOn
  }    
}
`;


export const updateSensorAssignLocation = `
mutation updateSensorAssignLocation($user_id: uuid = "", $id: uuid = "", $component_id: uuid = "", $component_location_id: uuid = "", $description: String = "") {
  update_zeus_sensor_by_pk(
    pk_columns: {id: $id}, 
    _set: {
    component_id: $component_id,
    component_location_id: $component_location_id, 
    modifiedOn: "now()"
  }) {
    __typename
    id
    name
    alias
    client_id
    component_id
    component_location_id
    description
    createdOn
    status
    client {
      __typename
      id
      name
      createdOn
      status
    }
  }
  insert_zeus_activity_one(object: {user_id: $user_id, parent_id: $id, name: "sensor assigned to component location", description: $description}) {
    __typename
    id
    name
    description
    createdOn
  }  
}
`;


export const updateSensorAssignComponent = `
mutation updateSensorAssignComponent($user_id: uuid = "", $id: uuid = "", $component_id: uuid = "", $description: String = "") {
  update_zeus_sensor_by_pk(pk_columns: {id: $id}, _set: {component_id: $component_id, modifiedOn: "now()"}) {
    __typename
    id
    name
    alias
    client_id
    parent_id
    description
    createdOn
    status
    client {
      __typename
      id
      name
      amount
      createdOn
      status
    }
  }
  insert_zeus_activity_one(object: {user_id: $user_id, parent_id: $id, name: "sensor assigned to component", description: $description}) {
    __typename
    id
    name
    description
    createdOn
  }    
}
`;


export const updateUserAssignRole = `
mutation updateUserAssignRole($admin_id: uuid = "", $user_id: uuid = "", $role_id: uuid = "", $description: String = "") {
  insert_zeus_user_to_role_one(object: {user_id: $user_id, role_id: $role_id}) {
    id
    role_id
    createdOn
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _set: {modifiedOn: "now()"}) {
    __typename
    id
    firstName
    lastName
    alias
    description
    createdOn
    modifiedOn
    status
  }
  insert_zeus_activity_one(object: {user_id: $admin_id, parent_id: $user_id, name: "role assigned to user", description: $description}) {
    __typename
    id
    name
    description
    createdOn
  }    
}
`;


//updateTicketAssignTech
export const updateTicketAssignTech = `
mutation updateTicketAssignTech($id: uuid = "", $_set: zeus_ticket_set_input = {}, $activity: zeus_activity_insert_input = {}) {
  update_zeus_ticket_by_pk(pk_columns: {id: $id}, _set: $_set) {
    __typename
    id
    name
    description
    createdOn
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }    
}
`;


export const updateAnnotation = `
mutation updateAnnotation($id: uuid = "", $user_id: uuid = "", $_set: zeus_annotation_set_input = {}) {
  update_zeus_annotation_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
    name
    description
    createdOn
    modifiedOn
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {annotation_id: $id, description: "updated annotation" createdOn: "now()"}}) {
    __typename
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const addQuotation = `
mutation addQuotation($user_id: uuid = "", $id: uuid="", $name: String="", $description: String="", $createdOn: timestamp="") {
  insert_zeus_sales_document(objects: {
    id: $id,
    name: $name, 
    description: $description, 
    createdOn: $createdOn,
    sales_document_type: "Quotation"
  }) {
    returning {
      id
      name
      description
      createdOn
      status
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {document_id: $id, name: $name, description: "created new quotation" createdOn: $createdOn}}) {
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;

export const addJobBoardEntry = `
mutation addJobBoardEntry($user_id: uuid = "", $id: uuid="", $object: zeus_jobboard_entry_insert_input = {}, $activity: zeus_activity_insert_input = {} ) {
  insert_zeus_jobboard_entry(objects: [$object]) {
    id
    name
    jobCounter
    description
    primacQuoteName
    createdOn
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {job_id: $id, description: "created job board entry" createdOn: "now()"}}) {
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;

export const addJobBoardEntryDialog = `
mutation addJobBoardEntryDialog($user_id: uuid = "", $id: uuid="", $object: zeus_jobboard_entry_insert_input = {}, $activity: zeus_activity_insert_input = {}) {
  insert_zeus_jobboard_entry_one(object: $object) {
    id
    name
    description
    primacQuoteName
    createdOn
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {job_id: $id, description: "created job board entry" createdOn: "now()"}}) {
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const addJobBoardEntryChildren = `
mutation addJobBoardEntryChildren($user_id: uuid = "", $id: uuid="", 
  $activities: [zeus_activity_insert_input!] = {},
  $documents: [zeus_sales_document_insert_input!] = {} ) {
  insert_zeus_activity(objects: $activities) {
    returning {
      __typename
      id
      name
      description
      createdOn
    }
  }   
  insert_zeus_sales_document(objects: $documents) {
    returning {
      id
      name
      description
      status
      createdOn
    }
  }  
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {job_id: $id, description: "created job board entry children" createdOn: "now()"}}) {
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const addJobBoardEntry_1 = `
mutation addJobBoardEntry($user_id: uuid = "", $id: uuid="", $object: zeus_jobboard_entry_insert_input = {}, $activity: zeus_activity_insert_input = {} ) {
  insert_zeus_jobboard_entry_one(object: $object) {
    id
    name
    description
    primacQuoteName
    createdOn
    status
  }
  insert_zeus_activity_one(object: $activity) {
    __typename
    id
    name
    description
    createdOn
  }   
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {job_id: $id, description: "created job board entry" createdOn: "now()"}}) {
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const addPurchaseOrder = `
mutation addPurchaseOrder($user_id: uuid = "", $object: zeus_sales_document_insert_input = {}) {
  insert_zeus_sales_document_one(object: $object) {
    id
    name
    description
    document_type
    createdOn
    status
    client {
      id
      name
      description
    }
    jobBoardEntry {
      id
      name
      description
      createdOn
      customerPO
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {document_id: $id, description: "created new purchase order" createdOn: $createdOn}}) {
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const updateActivity = `
mutation updateActivity($user_id: uuid = "", $id: uuid = "", $_set: zeus_activity_set_input = {}) {
  update_zeus_activity(where: {id: {_eq: $id}}, _set: $_set) {
    returning {
      id
      name
      description
      status
      createdOn
      closedOn
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {activity_id: $id, description: "updated activity" createdOn: $createdOn}}) {
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const updateProductLine = `
mutation updateProductLine($user_id: uuid = "", $id: uuid = "", $_set: zeus_product_line_set_input = {}) {
  update_zeus_product_line(where: {id: {_eq: $id}}, _set: $_set) {
    returning {
      id
      name
      description
      status
      createdOn
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {productline_id: $id, description: "updated product line" createdOn: $createdOn}}) {
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const updateClientNode = `
mutation updateClientNode($user_id: uuid = "", $id: uuid = "", $_set: zeus_client_node_set_input = {}) {
  update_zeus_client_node(where: {id: {_eq: $id}}, _set: $_set) {
    returning {
      id
      name
      description
      createdOn
      modifiedOn
      status
    }
  }
  update_zeus_zeus_user_by_pk(pk_columns: {id: $user_id}, _append: {history: {node_id: $id, description: "updated client node" createdOn: $createdOn}}) {
    id
    alias
    email
    firstName
    lastName
    createdOn
    status
  }
}
`;


export const addClientNode = `
mutation addClientNode($object: zeus_client_node_insert_input = {}, $activities: [zeus_activity_insert_input!] = {}) {
  insert_zeus_client_node_one(object: $object) {
    __typename
    id
    name
    description
    createdOn
    status
    parent_id
    client_id
  }
  insert_zeus_activity(objects: $activities) {
    returning {
      __typename
      id
      name
      description
      createdOn
    }
  }   
}
`;
